import React from 'react'; // , { useState }
import PropTypes from 'prop-types';

// react theme/style
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { Collapse, SvgIcon } from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';

// MUI core
//

// MUI icons
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

// Gatsby
//

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support

// Local : see src/index.js for available components
//

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

/* 
function CloseSquare(props) {
  return (
    <SvgIcon className="close" fontSize="inherit" {...props}>
      { / * tslint:disable-next-line: max-line-length * / }
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}
*/
function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 12,
    paddingLeft: 12,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))(props => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles({
  root: {
    height: 'auto',
    flexGrow: 1,
    maxWidth: 400,
    margin: '5px',
    padding: '15px',
  },
  selected: {
    color: 'green',
    marginLeft: 8,
    width: 20,
    height: 20,
  },
  unselected: {
    color: 'red',
    marginLeft: 8,
    width: 20,
    height: 20,
  },
});

const Tree = props => {
  const { bitMap, categories, handleModelChange } = props;

  const classes = useStyles();
  const [ctr, setCtr] = React.useState(0);

  const labelIcon = item => {
    const result = item.selection ? (
      <>
        <span>{item.name}</span>
        <span title="Click to check or uncheck">
          <CheckIcon className={classes.selected} onClick={event => handleClick(event, item)} />
        </span>
      </>
    ) : (
      <>
        <span>{item.name}</span>
        <span title="Click to check or uncheck">
          <ClearIcon className={classes.unselected} onClick={event => handleClick(event, item)} />
        </span>
      </>
    );

    return result;
  };

  const percolate = (item, i) => {
    if (!item.category) {
      return !item.selection;
    }

    const isUnselectedChild = item.category.find(child => {
      if (child.category) {
        return percolate(child);
      } else return !child.selection;
    });

    item.selection = !isUnselectedChild;
    return !item.selection;
  };

  const handleClick = (event, item) => {
    //console.log('Tree:handleClick: ' + item.name + ', ' + item.id);
    item.selection = item.selection ? false : true;
    //console.log('Tree:handleClick selection changed to ' + item.selection);
    if (item.category) {
      // if parent was set/unset, then do the same for all of it's children
      item.category.forEach(cat => (cat.selection = item.selection));
    }
    // percolate upwards to parent. ie. if this was child and unselected then parent must also be unselected.
    percolate(categories, 0);

    //console.log('Tree: pre bitmap check ' , bitMap);
    // Set/Unset bitmaps based on category item selection
    bitMapCheck(categories, 0);
    //console.log('Tree: post bitmap check ' , bitMap);

    // Force a rerender
    setCtr(ctr + 1);

    // Update the actual consumer object with changes using bitMap
    handleModelChange();

    event.stopPropagation();
  };

  const treeItems = (item, i) => {
    if (!item) return;

    /* eslint-enable no-unused-vars */

    const children =
      item.category &&
      item.category.map((cat, i) => {
        return treeItems(cat, i);
      });

    return (
      <StyledTreeItem
        nodeId={item.reg + '-' + item.id}
        label={labelIcon(item)}
        key={item.reg + '-' + item.id}
      >
        {children}
      </StyledTreeItem>
    );
    /* eslint-disable no-unused-vars */
  };

  // There are three registers or bitmaps.
  //
  /*
  let bitMap = [
      new Array(64).fill(0),
      new Array(64).fill(0),
      new Array(64).fill(0)
  ];
*/

  const bitMapCheck = (item, i) => {
    if (!item) return;

    const children =
      item.category &&
      item.category.map((cat, i) => {
        return bitMapCheck(cat, i);
      });

    bitMap[Number(item.reg)][Number(item.id)] = item.selection ? '1' : '0';

    //if (item.selection)
    //  console.log('bitMapCheck : set true for ' + item.reg + '/' + item.id);

    return;
  };

  // bitMapCheck(categories, 0);
  // console.log('tree bitmap #0 is:' + bitMap[0].join(''));
  // console.log('tree bitmap #1 is:' + bitMap[1].join(''));
  // console.log('tree bitmap #2 is:' + bitMap[2].join(''));

  // console.log('>>>>>>>>>>>> RENDER TREE <<<<<<<<<<<<');

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={['0-0']}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      //defaultEndIcon={<CloseSquare />}
      //onNodeToggle={handleChange}
    >
      {treeItems(categories, 0)}
    </TreeView>
  );
};

export default Tree;

/*
      <StyledTreeItem nodeId="1" label="Main 1" onClick={event => handleClick(event, '1')}>
        <StyledTreeItem nodeId="2" label="Hello 2"  onClick={event => handleClick(event, '2')} />
        <StyledTreeItem nodeId="3" label="Subtree with children 3" onClick={event => handleClick(event, '3')} >
          <StyledTreeItem nodeId="6" label=<SelectCB/>  onClick={event => handleClick(event, '6')}/>
          <StyledTreeItem nodeId="7" label="Sub-subtree with children 7" onClick={event => handleClick(event, '7')}>
            <StyledTreeItem nodeId="9" label="Child 1 9" onClick={event => handleClick(event, '9')} />
            <StyledTreeItem nodeId="10" label="Child 2 10" onClick={event => handleClick(event, '10')} />
            <StyledTreeItem nodeId="11" label="Child 3 11" onClick={event => handleClick(event, '11')} />
          </StyledTreeItem>
          <StyledTreeItem nodeId="8" label="GM 8"  onClick={event => handleClick(event, '8')}/>
        </StyledTreeItem>
        <StyledTreeItem nodeId="4" label="World 4"  onClick={event => handleClick(event, '4')}/>
        <StyledTreeItem nodeId="5" label="Something something 5"  onClick={event => handleClick(event, '5')}/>
      </StyledTreeItem>
  
*/
