import React, { useState, useContext } from 'react';

// react theme/style
import { makeStyles } from '@material-ui/core/styles'; // useTheme,

// MUI core
import { Button, Fab, Grid, Typography, Box } from '@material-ui/core'; // Link , Avatar
import { InputLabel, MenuItem, FormControl, Select, Container } from '@material-ui/core';

// MUI icons
//

// Gatsby
import { Link } from 'gatsby'; // , graphql

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//

// Local : see src/index.js for available components
import { PageHeader, SectionLayout, SEOJsonLd } from '../../../src';

const useStyles = makeStyles(theme => {
  return {
    step: {
      padding: '1.0rem',
    },
    desc: {
      padding: '2.0rem',
      margin: '1.0rem',
      verticalAlign: 'center',
      lineHeight: '2.0rem',
      border: '1px solid gray',
    },
    supply: {
      padding: '1.0rem',
      marginBottom: '1.0rem',
    },
    tools: {
      padding: '1.0rem',
      marginBottom: '1.0rem',
    },
  };
});

//
// Compose Section Areas
//
const HowToFormatter = props => {
  //const theme = useTheme();
  const classes = useStyles();

  const { howToData } = props;

  const howToSteps = (
    <div>
      {howToData.content.step.map((data, index) => {
        return (
          <Grid item xs={12} key={`content_step_${index}`} className={classes.step}>
            <Typography variant="h5" gutterBottom>
              {1 + index + '. ' + data.name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <span dangerouslySetInnerHTML={{ __html: data.text }}></span>
            </Typography>
            <Typography variant="caption" gutterBottom>
              {data.image ? 'Include image and URL for the step' : null}
            </Typography>
          </Grid>
        );
      })}
    </div>
  );

  const page = howToData.meta.title;
  const title = '';

  return (
    <SectionLayout>
      <Container maxWidth="md">
        <Grid item xs={12}>
          <PageHeader page={page} title={title} />
        </Grid>

        <br />

        <Grid container item xs={12}>
          <Grid item xs={12} sm={6}>
            <img src={howToData.content.image.url} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" gutterBottom className={classes.desc}>
              {howToData.content.description}
            </Typography>
          </Grid>

          <Grid item xs={6} className={classes.supply}>
            <Typography variant="h5" gutterBottom>
              Supply:
            </Typography>
            {howToData.content.supply.map(item => {
              return (
                <Typography variant="subtitle1" gutterBottom>
                  {item.name}
                </Typography>
              );
            })}
          </Grid>

          <Grid item xs={6} className={classes.tools}>
            <Typography variant="h5" gutterBottom>
              Tools:
            </Typography>
            <Typography variant="p" gutterBottom>
              {howToData.content.tool.map(item => item.name)}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.tools}>
            <Typography variant="h4" align="center" gutterBottom>
              Steps
            </Typography>
          </Grid>

          {howToSteps}
        </Grid>
      </Container>
    </SectionLayout>
  );
};

export default HowToFormatter;
