import React from 'react'; // , useEffect, { useState }

// react theme/style
import { makeStyles } from '@material-ui/core/styles'; // useTheme,

// MUI core
import { Button, Grid } from '@material-ui/core'; // Link Avatar, Typography

// MUI icons
//

// Gatsby
//import { Link, graphql } from 'gatsby';

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//

// Local : see src/index.js for available components
import { SectionWrapper, SectionLayout } from '../../../src';

const useStyles = makeStyles(theme => {
  return {
    textHilite: {
      color: theme.palette.secondary.main,
    },
    ctabtn: {
      margin: theme.spacing(1, 10),
      padding: theme.spacing(2),
      width: '300px',
      letterSpacing: '0.2em',
    },
  };
});

//
// Compose Section Areas
//
const SampleSection = props => {
  //const theme = useTheme();
  const classes = useStyles();

  //
  // Title Area
  //
  const title = (
    <>
      <span>Col Title </span>
    </>
  );
  const subtitle = 'Col Sub Title';

  //
  // Body Area
  //
  const body = (
    <>
      <span>Col Body Text</span>
    </>
  );

  //
  // Tail Area
  //
  const tail = (
    <Button href="/pricing" variant="contained" color="secondary" className={classes.ctabtn}>
      Call To Action Button
    </Button>
  );

  return (
    <SectionLayout>
      <Grid item xs={12}>
        <SectionWrapper title={title} subtitle={subtitle} body={body} tail={tail} />
      </Grid>
    </SectionLayout>
  );
};

export default SampleSection;
