import React, { useContext } from 'react'; // , { useState }

// react theme/style
import { makeStyles } from '@material-ui/core/styles'; // useTheme,

// MUI core
import { Button, Grid, Typography } from '@material-ui/core'; // Link , Avatar, Typography

// MUI icons
//

// Gatsby
//import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//

// Local : see src/index.js for available components
//import { GlobalContext, SectionLayout, particles } from '../../../src';

const useStyles = makeStyles(theme => {
  return {

    imageBox: {
      /* Here's the trick */
      background: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.1)), var(--image-url) center center',

      /* Here's the same styles we applied to our content-div earlier */
      color: 'white',
      minHeight: '46vh',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'left',
      objectFit: 'scale-down',
      backgroundSize: 'cover',
    },
    textOverlay: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '1rem 0 1rem 2rem',
    },
    title: {
    },
    subtitle: {
    },
  }
});

//
// Compose Section Areas
//
const Comp = props => {
  //const theme = useTheme();
  const classes = useStyles();
  const { pageContent } = props;

  //const { global } = useContext(GlobalContext); // , setGlobal

  //<div className={classes.imageBox} style={{"--image-url": "url(https://picsum.photos/3000/500)" }}>

  const imageUrl = `url(${pageContent.image})`;
  return (
    <div className={classes.imageBox} style={{"--image-url": imageUrl }}>
      <div className={classes.textOverlay}>
	<Typography component="h1" variant="h2" gutterBottom dangerouslySetInnerHTML={{ __html: pageContent.title }} className={classes.title}/>
	<Typography component="h3" variant="h4" gutterBottom dangerouslySetInnerHTML={{ __html: pageContent.subTitle }} className={classes.subtitle}/>
      </div>
    </div>
  );
};

export default Comp;


/*    <SectionLayout option={'nopadding'}>
<BackgroundImage className={classes.masthead} fluid={images[rand].node.childImageSharp.fluid}>
<div className={classes.mastOverlay}>
<h1 className={classes.recall}>RECALL</h1>
<div className={classes.content}>
{title}
{subtitle}
{body}
{tail}
</div>
</div>
</BackgroundImage>
</SectionLayout>
*/
