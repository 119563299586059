import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
//import favicon from '../../images/favicon.png';
import { SEOJsonLd } from '../../../src';
import seodata from './seodata.json';

const PageSeo = props => {
  const { seoInfo, siteMetadata, logoImg } = props; // logoImg is actual binary image for the site logo

  const {
    //bgColor,
    //pathPrefix,
    siteUrl, // just the host name to visit the site w/o trailing slash
    orgSeoCode,
    personSeoCode,
  } = siteMetadata;

  const LOGO_HEIGHT = 48;
  const LOGO_WIDTH = 48;

  // Extract data for org and person   (see below for an example of obj properties)
  const org =
    orgSeoCode && seodata.org[orgSeoCode] ? seodata.org[orgSeoCode] : seodata.org['kripa'];

  if (!org) {
    console.log(
      'PageSeo : Invalid orgSeoCode specified : ' + orgSeoCode + ' SEO processing aborted',
    );
    return;
  }

  // Extract data for person
  const person =
    seoInfo && seoInfo.personSeoCode
      ? seodata.person[seoInfo.personSeoCode]
      : personSeoCode && seodata.person[personSeoCode]
      ? seodata.person[personSeoCode]
      : seodata.person['default'];

  const isPost = seoInfo.type === 'BlogIndexPage' || seoInfo.type === 'BlogPage';
  const fullSetKeywords = seoInfo.keywords ? seoInfo.keywords : org.keywords;
  const fullPath = `${siteUrl}${seoInfo.path}`; // path to BlogPage or WebPage

  const slugWithoutSlashes = () => (isPost ? seoInfo.slug.replace(/\//g, '') : seoInfo.slug);

  const desc = seoInfo.description ? seoInfo.description.substring(0, 150) : org.description;

  const metaList = [
    {
      name: 'description',
        content: desc,
    },

    {
      property: 'keywords',
      content: fullSetKeywords.join(','),
    },

    // og: xxxx
    {
      property: 'og:locale',
      content: 'en_US',
    },
    {
      property: `og:type`,
      content: isPost ? 'article' : 'website', // 'article' for a blogpost and 'website' for a web page
    },
    {
      property: `og:title`,
      content: isPost
        ? `${seoInfo.ogTitle} · ${org.title}`
        : `${seoInfo.title} · ${org.title}` || `${org.title} · ${org.slogan}`,
    },
    {
      property: `og:site_name`,
      content: org.name,
    },
    {
      property: 'og:description',
        content: desc,
    },
    {
      property: 'og:url',
      content: fullPath, // seoInfo.slug ? `${siteUrl}/${slugWithoutSlashes()}/` : org.siteUrl + '/'
    },
    {
      property: 'og:app_id',
      content: org.fbAppID,
    },

    // twitter
    {
      name: 'twitter:card',
      content: isPost ? 'summary_large_image' : 'summary', // 'summary' OR 'summary_large_image'
    },
    {
      name: `twitter:site`,
      content: org.twitterID,
    },
    {
      name: `twitter:creator`,
      content: isPost ? seoInfo.twitterID || org.twitterID : org.twitterID,
    },
    {
      name: `twitter:title`, // title of the content - max of 70 characters
      content: isPost
        ? seoInfo.twitterTitle || seoInfo.title
        : `${org.twitterTitle} · ${org.title}`,
    },
    {
      name: `twitter:description`,
      content: isPost ? seoInfo.twitterDesc || seoInfo.description : org.twitterDesc, // description - max of 200 characters and describes the content
    },

    /*  ??? What to do here
       // monetization
       {
       name: `monetization`,
       content: '$ilp.uphold.com/yhXKK7rBEAyw',  // ???
       },
     */
  ];

  //
  // Image Handling   (use Gatsby Img object or plain Url
  //

  /*
  let googImage = logoImg,
    fbImage = logoImg,
    twitterImage = logoImg;
*/

  let googImage, fbImage, twitterImage;

  let googImageUrl, fbImageUrl, twitterImageUrl;
  let imageName, imageSuffix, imageUrl, imageAlt;

  /*
  if (logoImg && logoImg.url) {  // force using URL
    // image is gatsby Img object
    imageName = logoImg.url.substr(0, logoImg.url.lastIndexOf('.')-1);
    imageSuffix = logoImg.url.substr(logoImg.url.lastIndexOf('.')+1);
    imageAlt = 'Image Alt Logo';
    googImageUrl = logoImg.url;
    fbImageUrl = imageName ? `${siteUrl}/${imageName}-twitter.${imageSuffix}` : '';
    twitterImageUrl = imageName ? `${siteUrl}/${imageName}-org.${imageSuffix}` : '';
  }	
  else {
    // image is NOT gatsby Img object
    imageName = logoUrl && logoUrl.substr(0, logoUrl.lastIndexOf('.')-1);
    imageSuffix = logoUrl && logoUrl.substr(logoUrl.lastIndexOf('.')+1);
    imageAlt = 'Picture Alt Logo';
    googImageUrl = logoImg.url;
    fbImageUrl = imageName ? `${siteUrl}/${imageName}-twitter.${imageSuffix}` : '';
    twitterImageUrl = imageName ? `${siteUrl}/${imageName}-social.${imageSuffix}` : '';
  }
*/

  imageName = org.logoUrl && org.logoUrl.substr(0, org.logoUrl.lastIndexOf('.'));
  imageSuffix = org.logoUrl && org.logoUrl.substr(org.logoUrl.lastIndexOf('.') + 1);
  imageAlt = 'Picture of Logo';
  googImageUrl = org.sitePic ? `${siteUrl}/${org.sitePic}` : logoImg.url;                   // images/logo/sitePic.png
  //fbImageUrl = imageName ? `${siteUrl}/${imageName}_social.${imageSuffix}` : '';
  fbImageUrl = org.sitePic ? `${siteUrl}/${org.sitePic}` : '';                              // images/logo/sitePic.png
  //twitterImageUrl = imageName ? `${siteUrl}/${imageName}_social.${imageSuffix}` : '';     // logo_emert_social.png
  twitterImageUrl = org.sitePic ? `${siteUrl}/${org.sitePic}` : '';                         // images/logo/sitePic.png

  // Add following only if image exists
  metaList.push({
    name: `image`,
    content: googImage ? googImage : googImageUrl,
  });

  // Add following only if twitterImage exists
  if (twitterImageUrl.indexOf('https') !== -1) {
    metaList.push({
      name: `twitter:image:secure_url`,
      content: twitterImage ? twitterImage : twitterImageUrl,
    });
  } else {
    metaList.push({
      name: `twitter:image`,
      content: twitterImage ? twitterImage : twitterImageUrl,
    }); // aspect ratio of 1:1 with minimum dimensions of 144x144 or maximum of 4096x4096 pixels, less than 5MB  JPG, PNG, WEBP
  }
  metaList.push({ name: `twitter:image:alt`, content: imageAlt }); // 420 chars

  // Add following only if social image exists
  if (fbImageUrl.indexOf('https') !== -1) {
    metaList.push({ property: 'og:image:secure_url', content: fbImage ? fbImage : fbImageUrl });
  } else {
    metaList.push({ property: 'og:image', content: fbImage ? fbImage : fbImageUrl });
  }
  metaList.push({ property: 'og:image:alt', content: imageAlt });

  // favicon can be 192x192 png
  let homePageSD = null;
  const graph = [];

  //
  // All WebPages, MdxPage, BlogPage, AppPage get the Organization and Website types
  //
  graph.push({
    '@type': 'Organization',
    id: `${siteUrl}/#organization`,
    name: org.name,
    image: `${org.siteUrl}/${org.logoUrl}`,
    logo: {
      '@type': 'ImageObject',
      id: `${org.siteUrl}/#logo`,
      caption: org.caption,
      height: LOGO_HEIGHT,
      inLanguage: 'en-US',
      url: `${org.siteUrl}/${org.logoUrl}`,
      width: LOGO_WIDTH,
    },
    mainEntityOfPage: `${org.siteUrl}/#entity`,
    /*
       "sameAs": [
       {
       "id": `${siteUrl}/#organization-a`
       },
       {
       "id": `${siteUrl}/#organization-b`
       },
       {
       "id": `${siteUrl}/#organization-c`
       }
       ],
     */
    url: org.siteUrl + '/',
  });

  // WebSite (name, tagline, url, how to use internal search, sitelinks)
  //"@context": "https://www.w3.org/ns/activitystreams",
  // optional to xref "@id":"https://wordlift.io/blog/en/#organization"
  //"type": "http://schema.org/SearchAction",

  const websiteSD = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    id: `${org.siteUrl}/#website`,

    alternateName: org.alternateName,
    description: org.description,
    inLanguage: 'en-US',

    name: org.name,

    publisher: {
      '@type': 'Organization',
      '@id': `${org.siteUrl}/#organization`,
    }, // Publisher

    url: org.siteUrl + '/',
  };
  
  // http://localhost:8000/app/myemerts?display=list&type=search&term=sugar
  if (orgSeoCode === 'emert') {
    websiteSD.potentialAction = {
      '@type': 'SearchAction',
      'query-input': 'required name=search_term_string',
      target: `${org.siteUrl}/app/myemerts?display=list&type=search&term={search_term_string}`,
    };
  }

  graph.push(websiteSD); // website

  homePageSD = (
    <Helmet>
      <script type="application/ld+json" data-jsonld-type="site">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@graph': graph,
        })}
      </script>
    </Helmet>
  );

/*
 favicon is handled by gatsby plugin: gatsby-plugin-manifest
*/

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title={seoInfo.title}
        titleTemplate={`%s · ${org.title}`}
        defaultTitle={org.title}
        meta={metaList}
        link={[
          { rel: 'canonical', href: `${fullPath}` }
        ]}
      />

      {homePageSD}

      <SEOJsonLd
        type="build"
        isBlogPost={isPost}
        orgSeoCode={orgSeoCode}
        personSeoCode={personSeoCode}
        seoInfo={seoInfo}
      />
    </>
  );
};
/*
   NOTE: author is an object

 */

PageSeo.defaultProps = {
  description: ``,
};

PageSeo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};

export default PageSeo;

/*

   "principal": {
   "name": "Principal Blogger"
   "email": "yatin@kripa.com",
   "code": "principal",
   "avatarUrl": "images/person/principal_logo",
   "caption": "Principal Blogger Avatar",
   },

   "emert": {
   "logoUrl": "images/logo/logo_emert.png",   // organization SD - used as : {organization.siteUrl}/{organization.logoUrl}
   "name": "EMERT",                           // organization SD
   "siteUrl": "https://emert.org",            // must be w/o trailing slash
   "caption": "EMERT Company Logo",            // used for image altname    

   // SEO Specific (SEO.jsx & SEOJsonLd.jsx) to support Web Page & Blog Page JSON-LD
   "alternateName": "Recalls Emergency Alert Service",
   "description": "The only website where you can find a full list of all product recalls. Search historical recalls, register your vehicles and products to get real time notifications as well as submit your own product recalls. Be Informed. Be Aware. Be Safe.",
   "email": "info@emert.org",
   "favicon": "favicon/favicon.ico",               // avail as Gatsby Img Object
   "headline": "Recent government and product manufacturer issued recalls",
   "logoUrl":  "images/logo/logo_emert.png",                           // image associated with web site avail as Img Object 
   "keywords": ["Recalls", "Alerts", "Products", "NHTSA", "FDA", "FSIS", "CDC"],
   "name": "EMERT",
   "siteUrl": "https://emert.org",         // SEO.jsx, SEOJsonLd.jsx, also below in config, and some plugins rely on "siteUrl" set to host name
   "slogan": "Be Informed. Be Aware. Be Safe.",
   "title": "EMERT",   // NOTE: User supplied title is suffixed with this title string
   "url": "https://emert.org",


   "googleVerifyCode": "googleverification",
   "bingVerifyCode": "bingverification",


   "twitterID": "@emert",
   "fbAppID": "myfbid",

   "twitter": "",
   "linkedin": "",
   "facebook": "",
   "stackOverflow": "",
   "github": "",
   "instagram": "",
   "pinterest": "",
   "youtube": "",
   "email": "",
   "phone": "",
   "fax": "",
   "address": ""
   }

   seoInfo = {
   type,              // 'BlogIndexPage', 'BlogPage', 'MDXPage', 'WebPage', 'WebIndexPage', 'AppPage'

   personSeoCode,

   dateModified,      // date MDX blog/page updated
   datePublishe,      // date MDX blog/page created

   description,       // blog/page desc  
   keywords,          // blog/page keywords   (prefixed to site keywords)
   path,              // path for MDX Page/Blog/Web Page w/ trailing slash ie. /getstarted/ /blogs/blog1/
   pic,               // MDX Page/Blog image url   (not an Img object)
   slug,              // Only provided if it's a BlogPage
   slogan,            // Slogan for the page
   title,             // MDX Page/Blog/Web Page H1 level title used for <html> <title>    
   }
 */

/* Used from:

   src/pages/index.jsx - for the landing page
   src/pages/pricing.jsx
   src/pages/getstarted.jsx

   src/mdx/blogs/index.js - for the blog main page

   if (defaults.baseUrl === '' && typeof window !== 'undefined') {
   defaults.baseUrl = window.location.origin;
   }

   if (defaults.baseUrl === '') {
   console.error('Please set a baseUrl in your site metadata!');
   return null;
   }

   const title = post.title || defaults.title;
   const description = post.description || defaults.description;
   const url = new URL(post.path || '', defaults.baseUrl);
   const image = post.image ? new URL(post.image, defaults.baseUrl) : false;

   Facebook Needs:
   <meta property="og:locale" content={locale} />
   <meta property="og:url" content={url} />
   <meta property="og:type" content={type} />
   <meta property="og:title" content={title} />
   <meta property="og:description" content={desc} />
   <meta property="og:image" content={image} />
   <meta property="og:image:alt" content={desc} />

   Twitter Needs:
   {username && <meta name="twitter:creator" content={username} />}
   <meta name="twitter:card" content={type} />
   <meta name="twitter:title" content={title} />
   <meta name="twitter:description" content={desc} />
   <meta name="twitter:image" content={image} />
   <meta name="twitter:image:alt" content={desc} />

   Goog Needs:
   <html lang={siteLanguage ? siteLanguage : 'en'} />
   <link rel="canonical" href={pathname} />
   <meta name="description" content={seo.description} />

   {!article && (
   <script type="application/ld+json">
   {JSON.stringify(schemaOrgWebPage)}
   </script>
   )}
   {article && (
   <script type="application/ld+json">
   {JSON.stringify(schemaArticle)}
   </script>
   )}
   <script type="application/ld+json">
   {JSON.stringify(breadcrumb)}
   </script>

 */
