import React from 'react'; // , { useContext }

// react theme/style
import { makeStyles } from '@material-ui/core/styles'; // useTheme

// MUI core
import { Menu, MenuItem, Typography } from '@material-ui/core'; //

// MUI icons
//

// Gatsby
import { navigate } from 'gatsby';

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//

// Local : see src/index.js for available components
//
//import { GlobalContext } from '../../../src';

const useStyles = makeStyles(theme => {
  //console.log('emert Header makeStyles');
  //console.log(theme);

  const border = `5px solid ${theme.nav.linkHover}`;

  return {
    root: {
      flexGrow: 1,
    },
    menu: {
      backgroundColor: theme.palette.primary.main,
    },
    menuitem: {
      color: theme.nav.linkColor,	//       color: 'black',
      padding: theme.spacing(theme.rspv.sectionGapSmall * 1, theme.rspv.sectionGapSmall * 2),
    },
    link: {
      textDecoration: `none`,
      paddingBottom: '5px',

      '&:hover, &:focus': {
        textDecoration: `none`,
        borderBottom: border,
        paddingBottom: '0px',
      },
      color: theme.nav.linkColor,	//       color: 'black',
    },
    linkActive: {
      borderBottom: border,
    },
  };
});

export default function BurgerMenu(props) {
  //const theme = useTheme();
  const classes = useStyles();

  const { anchorEl, setAnchorEl, menuLinks, menuProc } = props;
  //const { global, setGlobal } = useContext(GlobalContext);

  const handleClose = () => {
    setAnchorEl(null);
  };

  //console.log('BurgerMenu rendered' + anchorEl );

  /*
  const menuProc = (e, menu) => {
    e.preventDefault();
    // if link is specified then redirect
    // if proc is specified then invoke it
    if (menu && menu.link) {
      navigate(menu.link);
    } else if (menu && menu.proc) {
      menu.proc(menu);
    } else {
      throw new Error('Invalid menu item');
    }
  };
*/
  const menuCurrent =
    (typeof window !== 'undefined' && window.location && window.location.pathname) || ''; // 'https://host:port/getstarted';

  const links = menuLinks.map((menu, i) => {
    const className =
      menu.link === menuCurrent ? classes.link + ' ' + classes.linkActive : classes.link;
    return (
      <MenuItem onClick={handleClose} key={i} className={classes.menuitem}>
        <a onClick={e => menuProc(menu)} className={className} key={i}>
          <Typography variant="button" display="block" gutterBottom color="inherit">
            {menu.name}
          </Typography>
        </a>
      </MenuItem>
    );
  });

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
	classes={{list: classes.menu}}
      >
        {links}
      </Menu>
    </div>

  );
}
