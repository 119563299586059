const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(utc);
dayjs.extend(relativeTime);

module.exports = dayjs;

//import dayjs from 'dayjs';
//import 'dayjs/locale/de';
//import AdvancedFormat from ‘dayjs/plugin/advancedFormat’;
//import relativeTime from 'dayjs/esm/plugin/relativeTime';

//dayjs.locale('de');
//dayjs.extend(AdvancedFormat);
