import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
//import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import IconPalette from './IconPalette';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  headers: {
    marginLeft: '0.8rem',
  },
}));

const MuiListComp = props => {
  const { bucket } = props; // items, format,
  const rows = bucket.cells;
  //const total = Number(bucket.options.total);

  //const emptyRows = 0; //rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const classes = useStyles();

  const getPrimaryValue = (row, i) => {
    return bucket.options.primaryDataFormatter(row, i);
  };

  const getSecondaryValue = (row, i) => {
    return bucket.options.secondaryDataFormatter(row, i);
  };

  return (
    <List className={classes.root}>
      <Typography component="div" className={classes.headers}>
        {bucket.columns}
      </Typography>
      <Divider variant="inset" component="li" />
      {rows.map((row, i) => {
        const primary = getPrimaryValue(row, i);
        const secondary = getSecondaryValue(row, i);

        return (
          <span key={i}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <IconPalette name={row['type']} />
              </ListItemAvatar>
              <ListItemText primary={primary} secondary={secondary} />
            </ListItem>
            {rows.length === i + 1 ? null : ( // No underline for last entry
              <Divider variant="inset" component="li" />
            )}
          </span>
        );
      })}
    </List>
  );
};

export default MuiListComp;
