//Can't use imports since gatsby-node.js uses require to pull in this util.js
var dayjs = require('./dayjs');
/*
const friendlyDate = dateIn => {
  const dateOut = dateIn.substr(0, 10);
  return displayDate(null, dateIn);
};
*/


var friendlyDate = function friendlyDate(dateIn) {
  //dayjs().from(dayjs('1990')) // 2 years ago
  //dayjs().from(dayjs(), true) // 2 years
  var dateOut = dayjs(dateIn).fromNow(); //const dateOut = dateIn.substr(0, 10);

  return dateOut; //return displayDate(null, dateIn);
};

var friendlyMonthDay = function friendlyMonthDay(dateIn) {
  var d = dayjs(dateIn);
  var diff = d.diff(dayjs(), 'year');
  if (diff) return d.format('MMM D, YYYY');else return d.format('MMM D ');
};

var relativeDaysDiff = function relativeDaysDiff(dateIn1, dateIn2) {
  if (!dateIn1) dateIn1 = new dayjs();else dateIn1 = new dayjs(dateIn1);
  if (!dateIn2) dateIn2 = new dayjs();else dateIn2 = new dayjs(dateIn2);
  return dateIn2.diff(dateIn1, 'day');
};

var friendlyTitle = function friendlyTitle(titleIn) {
  return titleIn;
};

var encode = function encode(str) {
  return str.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/[\r\n\t]+/g, '-').replace(/^-+/, '').replace(/-+$/, '') || 'untitled';
}; // slugify is also replicated into gatsby-node.js


var slugify = function slugify(slugType, emert) {
  var tit = emert && emert.jsonProp && emert.jsonProp.Title && emert.jsonProp.Title.substring(0, 80) || '';
  var desc = emert && emert.jsonProp && emert.jsonProp.Description && emert.jsonProp.Description.substring(0, 80) || '';
  var prodesc = emert.jsonProp.PRODUCTDESCRIPTIONTXT && emert.jsonProp.PRODUCTDESCRIPTIONTXT.substring(0, 80) || '';
  var title = tit || desc || prodesc;
  var slug = encode(title || 'untitled');
  return "/".concat(slugType, "/emert/").concat(emert.id, "-").concat(slug, "/");
};

var typeToTextList = {
  cpsc: 'Consumer Products',
  nhtsa: 'Vehicles',
  tire: 'Tires',
  equipment: 'Equipments',
  css: 'Child Safety Seats',
  fdacfsan: 'Food',
  fdacder: 'Drugs',
  fdacber: 'Biologics',
  fdacdrh: 'Devices',
  fdacvm: 'Veterinary',
  fdactp: 'Tobacco',
  fsis: 'Meat'
};

var recallTypeToText = function recallTypeToText(type) {
  return typeToTextList[type];
};

var displayNumber = function displayNumber(locale, value) {
  var options = {
    maximumSignificantDigits: 9
  };
  if (typeof value !== 'number' || isNaN(value)) return null;
  return new Intl.NumberFormat('en-US', options).format(value);
};

var displayDate = function displayDate(locale, value) {
  // 2020-05-29 04:00:00+00
  // 2020-05-29T04:00:00.000Z
  // 2020-07-06 18:56:18.469+00
  // 20200706 >>> 2020-07-06T09:00:00.000Z'
  // output: May 28, 2020   or July 28, 2020
  //
  if (process.env.GATSBY_USE_NEW_DISPLAYDATE === 'yes') return displayDateNew(locale, value); //const loc = locale ? locale : 'en-US';

  var isInvalid = false;

  if (value) {
    try {
      var dt = new Date(value);
      var options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      return new Intl.DateTimeFormat('en-US', options).format(dt);
    } catch (e) {
      isInvalid = true;
    }
  }

  if (isInvalid) {
    if (value.indexOf('-') === -1) {
      var newValue = value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6) + 'T09:00:00.000Z';

      try {
        var _dt = new Date(newValue);

        var _options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
        return new Intl.DateTimeFormat('en-US', _options).format(_dt);
      } catch (e) {
        isInvalid = true;
      }
    }

    return 'Invalid Date: ' + value;
  }

  return '';
};

var displayDateNew = function displayDateNew(locale, value) {
  // Consieer incomine 'value' to be in UTC time
  // return output in UTC time: May 28, 2020   or July 28, 2020
  //
  // 2020-05-29 04:00:00+00
  // 2020-05-29T04:00:00.000Z
  // 2020-07-06 18:56:18.469+00
  // 20200706 >>> 2020-07-06T09:00:00.000Z'
  //
  //const loc = locale ? locale : 'en-US';
  var retVal = '';
  var isInvalid = false;

  if (value) {
    try {
      retVal = dayjs.utc(value).format('MMM D, YYYY');
    } catch (e) {
      isInvalid = true;
    }
  }

  if (isInvalid) {
    if (value.indexOf('-') === -1) {
      var newValue = value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6) + 'T09:00:00.000Z';

      try {
        retVal = dayjs.utc(newValue).format('MMM D, YYYY');
      } catch (e) {
        isInvalid = true;
      }
    } else retVal = 'Invalid Date: ' + value;
  } //console.log('util:displayDateNew value: ' + value + ', returning ' + retVal);


  return retVal;
};

var suffixSeverity = function suffixSeverity(data, emertNo) {
  if (data.severity && data.severity === '1') return emertNo + '  ***';else if (data.severity && data.severity === '2') return emertNo + '   **';else if (data.severity && data.severity === '3') return emertNo + '    *';else if (data.severity && data.severity === '4') return emertNo + '     '; //if (data.severity && data.severity === '0')
  //return 'xxxxYYYYzzzz' + emertNo;
  else return emertNo + '     ';
};

var friendlyStatus = function friendlyStatus(value) {
  var status = value === 'load' ? 'Announced' : value === 'created' ? 'Announced' : value === 'updated' ? 'Updated' : value === 'notified' ? 'Notified' : 'Unknown';
  return status;
}; // slugify is pulled in gatsby-node.js using require()
// and hence need to use exports.module


module.exports = {
  friendlyDate: friendlyDate,
  friendlyMonthDay: friendlyMonthDay,
  relativeDaysDiff: relativeDaysDiff,
  friendlyTitle: friendlyTitle,
  slugify: slugify,
  recallTypeToText: recallTypeToText,
  displayNumber: displayNumber,
  displayDate: displayDate,
  suffixSeverity: suffixSeverity,
  friendlyStatus: friendlyStatus
};