import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
//import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
//import LocalDiningIcon from '@material-ui/icons/LocalDining';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import PetsIcon from '@material-ui/icons/Pets';
import DevicesIcon from '@material-ui/icons/Devices';
import HouseIcon from '@material-ui/icons/House';
import ColorizeIcon from '@material-ui/icons/Colorize';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CategoryIcon from '@material-ui/icons/Category';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AdjustIcon from '@material-ui/icons/Adjust'; // Tire
import EventSeatIcon from '@material-ui/icons/EventSeat'; // css
//import ChildCareIcon from '@material-ui/icons/ChildCare';
import HighlightIcon from '@material-ui/icons/Highlight'; // light - equipment
//import MeatIcon from '../icons/MeatIcon';
import CowIcon from '../icons/CowIcon';
import StethoscopeIcon from '../icons/StethoscopeIcon';
import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';

const useStyles = makeStyles(theme => ({
  root: {
    width: '1.2rem',
    height: '1.2rem',
    fontSize: '1.2rem',
    display: 'inline-block',
  },
}));

const IconPalette = props => {
  const { name, ...rest } = props;
  const classes = useStyles();

  let icon;
  switch (name) {
    case 'Vehicles':
      icon = <DirectionsCarIcon {...rest} />;
      break;
    case 'Equipments':
      icon = <HighlightIcon {...rest} />;
      break;
    case 'Child Safety Seats':
      icon = <EventSeatIcon {...rest} />;
      break;
    case 'Tires':
      icon = <AdjustIcon {...rest} />;
      break;
    case 'Drugs':
      icon = <LocalHospitalIcon {...rest} />;
      break;
    case 'Food':
      icon = <LocalGroceryStoreIcon {...rest} />;
      break;
    case 'Veterinary':
      icon = <PetsIcon {...rest} />;
      break;
    case 'Tobacco':
      icon = <SmokingRoomsIcon {...rest} />;
      break;
    case 'Devices':
    case 'Stethoscope':
      //icon = <span className={classes.root}> <MeatIcon viewBox="0 0 24 24" {...rest} /> </span>
      icon = (
        <span className={classes.root}>
          {' '}
          <StethoscopeIcon {...rest} />{' '}
        </span>
      );
      break;
    case 'Digital':
      icon = <DevicesIcon {...rest} />;
      break;
    case 'Consumer Products':
      icon = <HouseIcon {...rest} />;
      break;
    case 'Biologics':
      icon = <ColorizeIcon {...rest} />;
      break;
    case 'Left':
      icon = <KeyboardArrowLeftIcon {...rest} />;
      break;
    case 'Right':
      icon = <KeyboardArrowRightIcon {...rest} />;
      break;
    case 'FirstPage':
      icon = <FirstPageIcon {...rest} />;
      break;
    case 'LastPage':
      icon = <LastPageIcon {...rest} />;
      break;
    case 'Category':
      icon = <CategoryIcon {...rest} />;
      break;
    case 'Notification':
      icon = <NotificationsIcon {...rest} />;
      break;
    case 'Products':
      icon = <AddShoppingCartIcon {...rest} />;
      break;
    case 'Meat':
      //icon = <span className={classes.root}> <MeatIcon viewBox="0 0 24 24" {...rest} /> </span>
      icon = (
        <span className={classes.root}>
          {' '}
          <CowIcon {...rest} />{' '}
        </span>
      );
      break;
    default:
      icon = null;
  }

  return icon;
};

export default IconPalette;
