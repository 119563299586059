import React from 'react';

// react theme/style
import { makeStyles } from '@material-ui/core/styles'; // useTheme,
import { Box } from '@material-ui/core'; // useTheme,

// MUI icons
//

// Gatsby
//

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//

// Local : see src/index.js for available components
//

const useStyles = makeStyles({
  root: {
    overflowY: 'scroll',
  }, // a style rule
});

const Scroller = props => {
  //const theme = useTheme();
  const { height, width, className } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root + ' ' + className} style={{ height: height, width: width }}>
      {props.children}
    </Box>
  );
};

export default Scroller;
