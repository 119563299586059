import React from 'react'; // , { useState }

// react theme/style
import { makeStyles } from '@material-ui/core/styles'; // useTheme,

// MUI core
import { Paper } from '@material-ui/core';
//

// MUI icons
//

// Gatsby
//import { StaticQuery, graphql } from 'gatsby';

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//import { MDXProvider } from '@mdx-js/react';

// Local : see src/index.js for available components
//import { MDXComponents } from '../../../src';

//import css from '../../css/site.css';
//import '../mdx/markdown.css';

const useStyles = makeStyles(theme => {
  return {
    section: {
      width: '100%',
      // Margin bottom is for section bottom gap (to show darker background from slate)
      marginBottom: theme.spacing(theme.rspv.pageGapSmall),
      '@media (min-width: 600px)': {
        marginBottom: theme.spacing(theme.rspv.pageGapMedium),
      },
      '@media (min-width: 900px)': {
        marginBottom: theme.spacing(theme.rspv.pageGapLarge),
      },
    },
    padding: {
      // Section's inner padding
      paddingTop: '.4rem',
      paddingLeft: '.4rem',
      paddingRight: '.4rem',
    },
  };
});

const SectionLayout = props => {
  //const theme = useTheme();
  const classes = useStyles();
  const { option, children } = props;

  if (option === 'nopadding') {
    return <Paper className={classes.section}>{children}</Paper>;
  } else {
    return <Paper className={classes.section + ' ' + classes.padding}>{children}</Paper>;
  }
};

export default SectionLayout;
