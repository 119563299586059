import React from 'react'; // , { useState }

// react theme/style
import { makeStyles } from '@material-ui/core'; // useTheme,

// MUI core
//

// MUI icons
//

// Gatsby
//import { StaticQuery, graphql } from 'gatsby';

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//

// Local : see src/index.js for available components
// import { GlobalContextProvider, Header, Footer } from '../../../src';

//import css from '../../css/site.css';

const useStyles = makeStyles(theme => {
  return {
    layoutPanel: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },

    header: {
      order: 1,
    },
    loadprogress: {
      left: 0,
      top: '2px',
      height: '2px',
      width: '100%',
      order: 2,

      marginTop: theme.rspv.pageBarHeightSmall, // Height of the black AppBar

      '@media (min-width: 600px)': {
        marginTop: theme.rspv.pageBarHeightLarge,
      },
      '@media (min-width: 900px)': {
        marginTop: theme.rspv.pageBarHeightLarge,
      },
    },
    contentPanel: {
      order: 3,
      // Account for the NavBar height
      padding: theme.spacing(theme.rspv.pageGapSmall),
      paddingBottom: '0px',
      display: 'flex',
      justifyContent: "center",
      flexDirection: 'column',	
      '@media (min-width: 600px)': {
        padding: theme.spacing(theme.rspv.pageGapMedium),
        paddingBottom: '0px',
      },
      '@media (min-width: 900px)': {
        padding: theme.spacing(theme.rspv.pageGapLarge),
        paddingBottom: '0px',
      },
    },
    footer: {
      order: 4,
    },
  };
});

const HdrFtrLayout = props => {
  //const theme = useTheme(); // use the initTheme from imported file
  //console.log('************** HdrFtrLayout theme : ', theme);
  const classes = useStyles();

  const { children, header, footer } = props;

  //
  //
  //
  const Header = header;
  const Footer = footer;
  return (
    <>
      <div className={classes.layoutPanel}>
        <div className={classes.header}>
          <Header />
        </div>
        <div className={classes.loadprogress + ' loadprogressbar'} />
        <div className={classes.contentPanel}>{children}</div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default HdrFtrLayout;

/*
    <div className={classes.contentpanel}>
      <Header/>
      <div className={classes.workpanel}>
        <div className={`${classes.loadprogress} + ' loadprogressbar'`} />
        {children}
      </div>
    </div>
   <Footer/>




   contentpanely: {
   display: 'flex',
   flexDirection: 'column',
   height: '100%',
   // Account for the NavBar height
   marginTop: theme.rspv.pageBarHeightSmall, // Height of the black AppBar
   // Border around every page
   // But not adding for the bottom.
   // Each section will force it's own bottom border using margin
   padding: theme.spacing(theme.rspv.pageGapSmall),
   paddingBottom: '0px',

   '@media (min-width: 600px)': {
   marginTop: theme.rspv.pageBarHeightLarge,
   padding: theme.spacing(theme.rspv.pageGapMedium),
   paddingBottom: '0px',
   },
   '@media (min-width: 900px)': {
   marginTop: theme.rspv.pageBarHeightLarge,
   padding: theme.spacing(theme.rspv.pageGapLarge),
   paddingBottom: '0px',
   },
   },
   contentpanelx: {
   display: 'flex',
   flexDirection: 'column',
   },
   workpanel: {
   order: 2, 
   height: '100%',
   // Account for the NavBar height
   marginTop: theme.rspv.pageBarHeightSmall, // Height of the black AppBar
   // Border around every page
   // But not adding for the bottom.
   // Each section will force it's own bottom border using margin
   padding: theme.spacing(theme.rspv.pageGapSmall),
   paddingBottom: '0px',

   '@media (min-width: 600px)': {
   marginTop: theme.rspv.pageBarHeightLarge,
   padding: theme.spacing(theme.rspv.pageGapMedium),
   paddingBottom: '0px',
   },
   '@media (min-width: 900px)': {
   marginTop: theme.rspv.pageBarHeightLarge,
   padding: theme.spacing(theme.rspv.pageGapLarge),
   paddingBottom: '0px',
   },
   },
   filler: {
   order: 3, 
   flexGrow: '2',
   backgroundColor: 'white',
   },








*/
