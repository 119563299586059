import React from 'react'; // , { useState }

// react theme/style
//import { makeStyles } from '@material-ui/core/styles'; // useTheme,

// MUI core
import { Button, Grid, Paper, Typography } from '@material-ui/core'; // Link , Avatar, Typography

// MUI icons
//

// Gatsby
//import { Link, graphql } from 'gatsby';

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//

// Local : see src/index.js for available components
//import { SectionWrapper } from '../../../src';

/*
const useStyles = makeStyles(theme => {
  return {
    xxxx: {
    },
  };
});
*/

//
// Compose Section Areas
//
const FourOFour = props => {
  //const theme = useTheme();
  //const classes = useStyles();

  // NOTE: styles via 'useStyles' are not working properly for first render of 404 page.

  //
  // Tail Area
  //
  const homeButton = (
    <Button
      href="/"
      variant="contained"
      color="secondary"
      style={{ margin: 'auto', padding: '1.5rem', width: '60vw', letterSpacing: '0.2em' }}
    >
      Home
    </Button>
  );
  //          <SectionWrapper title={title} subtitle={subtitle} body={body} tail={tail} />

  return (
    <Paper style={{ backgroundColor: '#adc983', height: '130vh' }}>
      <Grid container>
        <Grid item xs={12}>
          <br />
          <Typography variant="h2" align="center" gutterBottom>
            Oh No! Page Not Found (404)
          </Typography>

          <br />
          <Typography variant="h4" align="center" gutterBottom>
            <img
              style={{ maxWidth: '80vw', maxHeight: '70vh', margin: 'auto' }}
              src="../../images/404/peaceful-buddha-chuck-purro.jpg"
              alt="Cooling depiction"
            />
          </Typography>
          <br />

          <Typography variant="h2" align="center" gutterBottom>
            Om Peace
          </Typography>
          <Typography variant="h4" align="center" gutterBottom>
            {homeButton}
          </Typography>
          <br />
          <br />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FourOFour;
