import React, { useState, useContext } from 'react';

// react theme/style
import { makeStyles } from '@material-ui/core'; // useTheme,

// MUI core
import { AppBar, Toolbar, Typography, Button, IconButton, useMediaQuery } from '@material-ui/core';

// MUI icons
import MenuIcon from '@material-ui/icons/Menu';

// Gatsby
import { useStaticQuery, Link, navigate, graphql } from 'gatsby';
import Img from 'gatsby-image';

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//

// Local : see src/index.js for available components
import { BurgerMenu, NavBar } from '../../../src'; // GlobalContext,

const useStyles = makeStyles(theme => {
  //console.log('emert Header makeStyles');
  //console.log(theme);

  return {
    masthead: {
      //      borderRadius: '14px',
      //animation: `$bgAnimation 15s ${theme.transitions.easing.easeInOut}`,
      //ease infinite',  // ${theme.transitions.easing.easeInOut}   ${theme.transitions.easing.easeInOut}
      height: '1140px',
      //backgroundImage: 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("/images/bg.jpg")',
      backgroundImage: props =>
        'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("' + props.bgimage + '")',
      backgroundSize: 'cover',
      //animation: bgAnimation 15s ease infinite;
      animationName: '$mastheadAni', // <<<<< Must specify the name with '$'
      animationDuration: '15s',
      animationTimingFunction: 'ease',
      animationIterationCount: 'infinite',
      padding: '1rem',
    },
    '@keyframes mastheadAni': {
      '0%': {
        backgroundPosition: 'left center',
      },
      '50%': {
        backgroundPosition: 'right center',
      },
      '100%': {
        backgroundPosition: 'left center',
      },
    },
    mastOverlay: {
      height: '100%',
      //display: '-webkit-flex',
      display: 'flex',
      flexDirection: 'column',
      //      backgroundColor: 'rgba(0.5,0.5,0.5,.2)',
      color: props => props.textColor,
      //backgroundImage: 'linear-gradient(to top, #cc3322, transparent 50%)',
      backgroundImage: props => 'linear-gradient(to top, ' + props.gradient + ', transparent 50%)',
      textAlign: 'center',
    },
    logo: {
      fontSize: '4rem',
      lineHeight: '1.3',
      letterSpacing: '0.6rem',

      position: 'relative',
      top: '2rem',
      order: '1',

      //animation: '$logoAni',                    // <<<<< Must specify the name with '$'
      //animationDuration: '1500ms',
      //animationFillMode: 'forwards',
    },
    title: {
      fontSize: '4rem',
      lineHeight: '1.3',

      position: 'relative',
      top: '3rem',
      order: '2',

      animation: '$titleAni', // <<<<< Must specify the name with '$'
      animationDuration: '1500ms',
      animationFillMode: 'forwards',
    },
    '@keyframes titleAni': {
      '0%': {
        top: '-300px',
      },
      '100%': {
        top: '3rem',
      },
    },
    subtitle: {
      fontSize: '2.4rem',
      lineHeight: '1.3',

      position: 'relative',
      top: '2rem',
      order: '3',

      animation: '$subtitleAni', // <<<<< Must specify the name with '$'
      animationDuration: '1500ms',
      animationFillMode: 'forwards',
      //      animationDelay: '1500ms',
    },
    '@keyframes subtitleAni': {
      '0%': {
        left: '-1000px',
      },
      '100%': {
        left: '0px',
      },
    },
    content: {
      fontSize: '1.6rem',
      lineHeight: '1.6',

      position: 'relative',
      top: '2rem',
      order: '4',
      width: '300px',
      marginLeft: 'auto',
      marginRight: 'auto',

      animationName: '$contentAni', // <<<<< Must specify the name with '$'
      animationDuration: '2s',
      animationFillMode: 'forwards',
      '@media (min-width: 600px)': {
        width: '600px',
      },
      '@media (min-width: 960px)': {
        width: '800px',
      },
    },
    '@keyframes contentAni': {
      '0%': {
        top: '1000px',
      },
      '100%': {
        top: '2rem',
      },
    },
    contactus: {
      fontSize: '2rem',
      lineHeight: '1.6',

      position: 'relative',
      top: '5rem',
      order: '5',
      marginLeft: 'auto',
      marginRight: 'auto',

      '& > a': {
        color: '#ffffff',
      },
    },
  };
});

const AnimatedImage = props => {
  // const theme = useTheme();
  //console.log('Emert Header theme: ', theme);

  const classes = useStyles(props);
  const { type, name, title, subtitle, content } = props; // bgimage, gradient, textColor,

  //const matchesMin960 = useMediaQuery('(min-width:960px)');
  //const matches1200 = useMediaQuery('(min-width:1200px)');

  /*
     const data = useStaticQuery(graphql`
     query MyQuery {
     file(relativePath: { eq: "logo/logo.png" }, sourceInstanceName: { eq: "images" }) {
     id
     childImageSharp {
     fluid(maxWidth: 32, maxHeight: 32) {
     ...GatsbyImageSharpFluid
     }
     }
     }
     site {
     siteMetadata {
     title
     description
     }
     }
     }
     `);

     const image = data.file;
     const title = data.site.siteMetadata.title;

   */
  let specificemail =
    'mailto:dnsoffer@kripa.com?subject=Offer to purchase domain names&body=We are interested in purchasing domain names. Our offer is as listed below: (Please add below your name, contact information and amount offered)';
  if (type === 'sale') {
    if (name === 'ChitLet')
      specificemail =
        'mailto:chitletoffer@kripa.com?subject=Offer to purchase ChitLet domain names&body=We are interested in purchasing ChitLet domain names. Our offer is as listed below: (Please add below your name, contact information and amount offered)';
    else if (name === 'CommunityOfferings')
      specificemail =
        'mailto:communityoffer@kripa.com?subject=Offer to purchase CommunityOfferings domain names&body=We are interested in purchasing CommunityOfferings domain names. Our offer is as listed below: (Please add below your name, contact information and amount offered)';
    else if (name === 'DoJoinUs')
      specificemail =
        'mailto:dojoinusoffer@kripa.com?subject=Offer to purchase DoJoinUs domain names&body=We are interested in purchasing DoJoinUs domain names. Our offer is as listed below: (Please add below your name, contact information and amount offered)';
    else if (name === 'PepLet')
      specificemail =
        'mailto:pepletoffer@kripa.com?subject=Offer to purchase PepLet domain names&body=We are interested in purchasing PepLet domain names. Our offer is as listed below: (Please add below your name, contact information and amount offered)';
    else if (name === 'uniys')
      specificemail =
        'mailto:uniysoffer@kripa.com?subject=Offer to purchase uniys domain names&body=We are interested in purchasing uniys domain names. Our offer is as listed below: (Please add below your name, contact information and amount offered)';
  }

  const href =
    type === 'sale'
      ? specificemail
      : 'mailto:info@kripa.com?subject=Early Access Request&body=We are interested in learning more about your early access program. Please contact us with details. (Please add below your name, contact information, site name and a reason for early access request.)';
  const contactus = type === 'sale' ? 'Inquire' : 'Contact For An Early Access';

  return (
    <div className={classes.masthead}>
      <div className={classes.mastOverlay}>
        <div className={classes.logo}>{name}</div>
        <h1 className={classes.title}>{title}</h1>
        <h3 className={classes.subtitle}>{subtitle}</h3>
        <div className={classes.content}>{content}</div>
        <div className={classes.contactus}>
          <a href={href}>{contactus}</a>
        </div>
      </div>
    </div>
  );
};

export default AnimatedImage;

/*

<Typography variant="h6" className={classes.title}>
News
</Typography>

<Typography variant="h6" color="primary">
<Link href="/about"
style={{
    color: `${theme.nav.color}`,
    textDecoration: `none`,
}}
>
{siteTitle}
</Link>
</Typography>



  const access = global.isLoggedIn() ? (
    <MenuItem onClick={handleClose} key={'logout'} className={classes.menuitem}>
      <a
        href="/"
        onClick={event => {
          event.preventDefault();
          global.logout();
          navigate(`/`);
          setGlobal({ ...global, token: null });
        }}
        className={classes.link}
      >
        Logout/Profile
      </a>
    </MenuItem>
  ) : (
    <MenuItem onClick={handleClose} key={'login'} className={classes.menuitem}>
      <a
        href="/"
        onClick={event => {
          event.preventDefault();
          global.redirectUrl('signin');
        }}
        className={classes.link}
      >
        Login
      </a>
    </MenuItem>
  );

  links.push(access);

*/

/*
   // Nav bar items
   nonUserNav = [
   { name: 'Home', link: '/', proc: null },
   { name: 'Pricing', link: '/pricing', proc: null },
   { name: 'Get Started', link: '/getstarted', proc: null },
   ];
   userNav = [
   //      { name: 'Home', link: '/', proc: null },
   { name: 'My Emerts', link: '/app/myemerts', proc: null },
   ];

   // Dropdown menu items
   nonUserDropdown = [
   { name: 'Sign In', link: null, proc: menuProc },
   { name: 'Forgot Password', link: null, proc: menuProc },
   ];
   userDropdown = [
   //      { name: 'Home', link: '/', proc: null },
   //{ name: 'My Emerts', link: '/app/myemerts', proc: null },
   { name: 'Sign Out', link: null, proc: menuProc },
   { name: 'Change Id', link: null, proc: menuProc },
   { name: 'Change Password', link: null, proc: menuProc },
   { name: 'Subscription', link: '/app/subscription', proc: null },
   { name: 'Monitor', link: '/app/monitor', proc: null },
   ];

   // Burger menu items
   nonUserBurger = [
   { name: 'Home', link: '/', proc: null },
   { name: 'Pricing', link: '/pricing', proc: null },
   { name: 'Get Started', link: '/getstarted', proc: null },
   { name: 'Sign In', link: null, proc: menuProc },
   { name: 'Forgot Password', link: null, proc: menuProc },
   ];
   userBurger = [
   { name: 'My Emerts', link: '/app/myemerts', proc: null },
   { name: 'Sign Out', link: null, proc: menuProc },
   { name: 'Change Id', link: null, proc: menuProc },
   { name: 'Change Password', link: null, proc: menuProc },
   { name: 'Subscription', link: '/app/subscription', proc: null },
   { name: 'Monitor', link: '/app/monitor', proc: null },
   ];
 */
