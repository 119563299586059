import React, { useContext } from 'react';

// react theme/style
import { makeStyles } from '@material-ui/core/styles'; // useTheme,

// MUI core
import { Grid, Button, Typography, Container, Divider, List, ListItem, ListItemIcon, ListItemText, Slide  } from '@material-ui/core'; // Link, Button, Paper, Avatar, Typography

// MUI icons

// Gatsby
import { Link } from 'gatsby';  // , graphql
//import { StaticImage } from "gatsby-plugin-image";
// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//

// Local : see src/index.js for available components
import {
//  useConsumer,
  GlobalContext,
//  MyEmertsSubscription,
//  SectionLayout,
//  PageHeader,
//  pages,
Banner,
  KripaRedBallIcon,
} from '../../../src';


const useStyles = makeStyles(theme => {
  return {
    page: {
      flexGrow: 1,
    },
    svgcontent: {
      //color: 'white',
      //fontSize: '20vmin',
      //lineHeight: 1,
      //fontWeight: 'bold',
      //letterSpacing: '2px',
      //fontFamily: 'Poppins, sans-serif',
      //textTransform: 'uppercase',
      //paddingLeft: '40px',
      zIndex: 100,
    },
    blob: {
      position: 'absolute',
      top: 0,
      left: 0,
      fill: '#cccccc', //'#023F92',  // blogGray
      width: '50vmax',
      //zIndex: -1,
      transformOrigin: '50% 50%',
    },
    blobAnimate: {
      animation: '$move 10s ease-in-out infinite',
    },
    '@keyframes move': {
      '0%':   { transform: 'scale(1)   translate(10px, -30px)' },
      '38%':  { transform: 'scale(0.8, 1) translate(80vw, 30vh) rotate(160deg)' },
      '40%':  { transform: 'scale(0.8, 1) translate(80vw, 30vh) rotate(160deg)' },
      '78%':  { transform: 'scale(1.3) translate(0vw, 50vh) rotate(-20deg)' },
      '80%':  { transform: 'scale(1.3) translate(0vw, 50vh) rotate(-20deg)' },
      '100%': { transform: 'scale(1)   translate(10px, -30px)' },
    },
    blobBlock: {
      margin: 0,
      padding: 0,
      width: 'auto',
      height: 'auto',
      overflow: 'hidden',
      position: 'relative',
      //background: '#FFB1A4',
      
      display: 'flex',
      alignItems: 'center',
    },

    // For listing sections and areas with hyper links
    linkitemVerlist: {
      marginTop: '1rem',
      marginLeft: '6rem',
    },
    linkitemHorlist: {
      marginTop: '2rem',
      marginLeft: '3rem',
    },
    linkitem: {
      padding: '0px',
      paddingRight: '2rem',
    },
    linkitemlink: {
      marginLeft: '-2.2rem',
      textDecoration: `none`,
      '&:hover, &:focus': {
        textDecoration: `none`,
      },
    },
    linkitemicon: {
      width: '12px',
      height: '12px',
    },
    linkorientationHorizontal: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'flex-start',
    },
    linkorientationVerticle: {
      display: 'flex',
      flexDirection: 'column',
    },

    // Used to denote draft version
    draft: {
      background: 'yellow',
    },
    pagePara: {
      padding: '0.2rem 1rem 0rem 1rem',
      '@media (min-width: 600px)': {
	padding: '1rem 6rem 0rem 6rem',
      },
      '@media (min-width: 900px)': {
        padding: '1rem 4rem 0rem 4rem',
      },
    },
    section: {
      padding: '1rem',
      '@media (min-width: 600px)': {
	padding: '2rem',
      },
      '@media (min-width: 900px)': {
        padding: '4rem',
      },
    },
    // For listing a separator at end of each Sections
    sectionFooter: {
      margin: '1rem 0rem',
      '@media (min-width: 600px)': {
	margin: '2rem 0rem',
      },
      '@media (min-width: 900px)': {
	margin: '4rem 0rem',
      },
    },
    sectionhr: {
      marginLeft: '25%',
      marginRight: '25%',
      borderBottomColor: '#0d2389',
      borderBottomStyle: 'dotted',
      borderBottomWidth: '7px',
      marginBottom: '2rem',
    },

    area: {
      margin: '1rem',
    },
    pageFooter: {
      margin: '1rem 0rem',
      '@media (min-width: 600px)': {
	margin: '2rem 0rem',
      },
      '@media (min-width: 900px)': {
	margin: '4rem 0rem',
      },
    },
  };
});

//
// Compose Page Sections
//
/* For picture
        <StaticImage alt='xxx',
                     src='image url' 
                     formats={['AUTO', 'WEBP', 'AVIP']}
                     placeholder='blurred' | 'none' | 'tracedSVG' | 'domianantColor'
                     backgroundColor='blue'
                     width={999}  // To be max of 999 and responsive for smaller size
                     layout='fullwidth' | 'fixed' - crops as screen gets smaller
                     aspectRatio={21 / 9}
                     loading='eager' - loads image ASAP
                     transformOptions={{grayscale: true, rotate: 90 }}
                     // images are responsive by default - to max of the original size
                     quality='2'  // 2 out of 100 for webp format
                     
        />

*/
const Comp = (props) => {
  //const theme = useTheme();
  const classes = useStyles();

  const { pageContent, GlobalContext } = props;

  const { global } = useContext(GlobalContext); // , setGlobal

  const isShowDraft = global.isShowDraft();

  
  const contentLinks = (type, content, secId) => {
    let contentList;
    if (type === 'sections') {
      contentList = content.sections;
    }
    else {
      contentList = content.areas;
    }

    let list = null;

    if (content.links && contentList.length > 2) {
      list = contentList.map( (item, i) => {
	if (!isShowDraft && item.draft) return null;

	const target = secId > -1 ? '#targetitem-' + secId + '-' + i : '#targetitem-' + i;

	const title = item.title.replace( /(<([^>]+)>)/ig, ''); // strip out HTML from title
	return (
	  <div key={'linkitem-'+i}>
          <ListItem className={classes.linkitem}>
	    <ListItemIcon>
	      <KripaRedBallIcon className={classes.linkitemicon}/>
	    </ListItemIcon>
	    <Link to={target}  className={classes.linkitemlink}>
              <ListItemText primary={title} primaryTypographyProps={{ color: "secondary" }} />
            </Link>
          </ListItem>
	  </div>
	);
      });
      const orientationClass = content.links === 'horizontal' ? classes.linkorientationHorizontal : classes.linkorientationVerticle;
      const listClass = content.links === 'horizontal' ? classes.linkitemHorlist : classes.linkitemVerlist;

      return (
	<div className={listClass}>
	  <Typography component="div" variant="h4" > {'OUR ' + pageContent.page.toUpperCase() + ':' } </Typography>
	  <List className={orientationClass}>
	    {list}
	  </List>
	</div>
      )
    }

    return null;
  };

  const singleAreaContent = (pageContent, section, secId, area, areaId) => {
    if (!isShowDraft && area.draft) return null;
    const isDraftClass = area.draft ? classes.draft : null;

    const lgCols = Math.round(12/section.cols || 3);
    let mdCols = Math.round(12/(section.cols/2) || 2);
    mdCols = mdCols > 12 ? 12 : mdCols;
    const areaStyle = area.style && area.style.box ? area.style.box : null ;
    const areaAnimate = area.style && area.style.animate ? area.style.animate : null ;
    const areaIcon = area.icon ? <img src={area.icon} />  : null;


    const areaPic = area.image ? <img width="140" src={area.image} /> : null;

    const areaQuestion = area.question ?
      <Typography variant="h6" color="textSecondary" gutterBottom dangerouslySetInnerHTML={{ __html: area.question }}/> : null;

    const areaTitle = area.title ?
      <Typography component="h3" variant="h5" gutterBottom dangerouslySetInnerHTML={{ __html: area.title }}/> : null;

    const areaSubTitle = area.subTitle ?
	<Typography component="h4" variant="h6" gutterBottom dangerouslySetInnerHTML={{ __html: area.subTitle }}/> : null;
    
    const areaPara = area.para && area.para.length && area.para.map( (p, pId) => {
      if (!p) return null;
      return(
	<Typography variant="body1" dangerouslySetInnerHTML={{ __html: p }} key={`sec-${secId}-area-${areaId}-p-${pId}`} style={{ padding: '0.8rem 0rem 0rem 0rem'}}  />
      )
    }) || null;

    const areaFooter = area.footer ?
      <Typography component="h3" variant="h5" gutterBottom dangerouslySetInnerHTML={{ __html: area.footer}}/> : null
    
    const areaSubFooter = area.subFooter ?
      <Typography variant="h6" gutterBottom dangerouslySetInnerHTML={{ __html: area.subFooter}} /> : null
    
    // There are no further sub details for areas (like the sections have for areas)
    const areaContent =
      (
	<div id={'targetitem-' + secId + '-' + areaId}>
	{ areaPic }
	{ areaQuestion }
	{ areaTitle }
	{ areaSubTitle }
        { areaPara }
        { areaFooter }
        { areaSubFooter }
	</div>
      );


      
    return (
      <Grid item xs={12} md={mdCols} lg={lgCols} key={`sec-${secId}-area-${areaId}`} className={isDraftClass} >
	{areaAnimate ?
	  <Slide direction={areaAnimate.direction} in={areaAnimate.in} mountOnEnter unmountOnExit timeout={{ enter: areaAnimate.timeoutEnter }} >
	    <div style={{...areaStyle}}>
	      { areaContent }
	    </div>
	  </Slide>
	  :
	  <div style={{...areaStyle}}>
	    { areaContent }
	  </div>
	}	  
      </Grid>
    ); // return
  }

  const areasContentFormat = (pageContent, section, secId) => {
    if (!section.areas) return null;

    const areasHtml = section.areas.map( (area, areaId) => {
      if (!area) return null;
      else {
	return singleAreaContent(pageContent, section, secId, area, areaId);
      }
    }); // return section area content

    return areasHtml;
  }

  // ===================================================================================================================

  //
  // Section LEVEL Formatter
  //
  const wrapSVG = (Content) => {
    let classValue = classes.blob;
    if (pageContent.svg.toLowerCase().indexOf('animate') !== -1) {
      classValue = classValue + ' ' + classes.blobAnimate;
    }
    return (
      <div className={classes.blobBlock}>
	<div className={classes.svgcontent}>
	  { Content }
	</div>
	<div className={classValue}>
	  {/* <!-- This SVG is from https://codepen.io/Ali_Farooq_/pen/gKOJqx --> */}
	  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="00 00 310 350">

	    <g fill="#f6d9d9" stroke="none"
	      >

  	      <path d="M156.4,339.5c31.8-2.5,59.4-26.8,80.2-48.5c28.3-29.5,40.5-47,56.1-85.1c14-34.3,20.7-75.6,2.3-111  
c-18.1-34.8-55.7-58-90.4-72.3c-11.7-4.8-24.1-8.8-36.8-11.5l-0.9-0.9l-0.6,0.6c-27.7-5.8-56.6-6-82.4,3c-38.8,13.6-64,48.8-66.8,90.3c-3,43.9,17.8,88.3,33.7,128.8c5.3,13.5,10.4,27.1,14.9,40.9C77.5,309.9,111,343,156.4,339.5z"/>

  	    </g>

	  </svg>
	</div>
      </div>
    );
  }

  const sectionBanner = (pageContent) => {
    if (!pageContent.image) {
      // No Image
      // Just list the title and sub title
      return (
	<div>
	  <Typography component="h1" variant="h2" gutterBottom dangerouslySetInnerHTML={{ __html: pageContent.title }}/>
	  <Typography component="h3" variant="h4" gutterBottom dangerouslySetInnerHTML={{ __html: pageContent.subTitle }}/>
	</div>
      )
    }
    else {
      // List title & sub title as overlay on image
      return (
	<>
	<Banner pageContent={pageContent} />
	<br/>
	{pageContent.para.map( (p, pId) => {
	   if (!p) return null;
	   return <Typography component="p" variant="h5" className={classes.pagePara} dangerouslySetInnerHTML={{ __html: p }}  key={`p-${pId}`}/>
	 })
	}
	</>
      );
    }
  }
  
  const singleSectionContent = (pageContent, section, secId) => {
    if (!isShowDraft && section.draft) return null;
    const isDraftClass = section.draft ? classes.draft : null;

    const i = 'https://images.unsplash.com/photo-1620840789492-31fcaf3be617?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80';
    const sectionPic = section.image ? <img width="100%" src={section.image} /> : null;
    //images are responsive by default - to max of the original size
/*
    const sectionPic = section.image ?
      <StaticImage alt='xxx'
        src={i}  //{section.image} 
        ///formats={['AUTO', 'WEBP', 'AVIP']}
        ///placeholder='blurred'               // | 'none' | 'tracedSVG' | 'domianantColor'
        ///backgroundColor='blue'
        ///width={999}                         // To be max of 999 and responsive for smaller size
        //layout='fullwidth' | 'fixed' - crops as screen gets smaller
        //aspectRatio={21 / 9}
        //loading='eager'                   // loads image ASAP
        //transformOptions={{grayscale: true, rotate: 90 }}
        //quality='2'                       // 2 out of 100 for webp format
      />
      :
      null;
*/
    const sectionQuestion = section.question ?
      <Typography variant="h4" color="textSecondary" gutterBottom  dangerouslySetInnerHTML={{ __html: section.question }}/>: null;

    // id is for traversing to section from the top of page section list
    const sectionTitle = section.title ?
      <Typography component="h2" variant="h3" gutterBottom dangerouslySetInnerHTML={{ __html: section.title }} /> : null;

    const sectionSubTitle = section.subTitle ?
      <Typography variant="h4" gutterBottom dangerouslySetInnerHTML={{ __html: section.subTitle }}/> : null;

    const sectionPara = section.para && section.para.length && section.para.map( (p, pId) => {
      if (!p) return null;
      return (
	<Typography variant="body1" dangerouslySetInnerHTML={{ __html: p }}  key={`sec-${secId}-p-${pId}`}  style={{ padding: '0.6rem 0rem 0rem 0rem'}} />
      )
    }) || null;

    const sectionFooter = section.footer ?
      <Typography component="h3" variant="h4" gutterBottom dangerouslySetInnerHTML={{ __html: section.footer }}/> : null;
    const sectionSubFooter = section.subFooter ?
      <Typography component="h4" variant="h5" gutterBottom dangerouslySetInnerHTML={{ __html: section.subFooter }}/> : null;

    const sectionAreaLinks = contentLinks('areas', section, secId);
    const sectionDetail = areasContentFormat(pageContent, section, secId);

    // Need the 4x<br/> to offset for NavBar offset
    const sectionContentTop = 
      (
	<div  id={'targetitem-'+secId} className={classes.section}>
	  { sectionQuestion }
	  { sectionTitle }
	  { sectionSubTitle }
	  { sectionPara }
	</div>
      );
    const sectionContentMid = 
      (
	<div className={classes.area}>
	  <Grid container  spacing={2}>
	    { sectionAreaLinks }
	    { sectionDetail }
	  </Grid>
	</div>
      );
    const sectionContentBot =
      (
	<div className={classes.sectionFooter}>
	  { sectionFooter }
	  { sectionSubFooter }
	</div>
      );
    
    // On odd sections, the image will be on right and on left for even sections
    return (
      <Grid item xs={12} key={`sec-${secId}`} className={isDraftClass}>
	  { secId % 2 === 0 ?
            
            <Grid container alignItems="center" spacing={2} >
  	      <Grid item xs={12} md={6}>
		{ sectionPic }
	      </Grid>
	      <Grid item xs={12} md={6}>
		{ sectionContentTop }
	      </Grid>
	    </Grid>
	    :
            <Grid container alignItems="center" spacing={2} >
	      <Grid item xs={12} md={6}>
		{ sectionContentTop }
	      </Grid>
  	      <Grid item xs={12} md={6}>
		{ sectionPic }
	      </Grid>
	    </Grid>

	  }
  	<Grid item xs={12}>
	  { sectionContentMid }
	</Grid>

  	<Grid item xs={12}>
	  { sectionContentBot }
	</Grid>
	
	<div className={classes.sectionhr}/>

      </Grid>
    );
  }  // return section content with all areas    

  const sectionsContentFormat = (pageContent) => {

    const sectionsHtml = pageContent.sections.map( (section, secId) => {
      if (!section) return null;
      else {
	return singleSectionContent(pageContent, section, secId);
      }
    }); // sections.map

    return sectionsHtml;
  }    

  const sectionsContent = (pageContent) => {
    return (
      <Container >

	<Grid item xs={12}>
	  { pageContent.svg ? wrapSVG(sectionsContentFormat(pageContent)) : sectionsContentFormat(pageContent) }
	</Grid>

	<Grid item xs={12} className={classes.pageFooter}>
  	  {pageContent.footer ?
	    <Typography variant="h3" gutterBottom dangerouslySetInnerHTML={{ __html: pageContent.footer}}/> : null
	  }
          {pageContent.subFooter ?
            <Typography variant="h5" gutterBottom dangerouslySetInnerHTML={{ __html: pageContent.subFooter}}/> : null
          }
	</Grid>

      </Container>
    );
  }

const banner = sectionBanner(pageContent);
const links = contentLinks('sections', pageContent, -1);  
const content = sectionsContent(pageContent);

return (
    <>
    { banner }
    { links }
    { content }
    </>
  );

};



export default Comp;

//
//
//   ------------------------------------ page image  ------------------------------
//   + page image overlay title                                                    + h1(tag) as h2(style)
//   +                                                                             +
//   + page image overlay subtitle                                                 + h3 as h4
//   -------------------------------------------------------------------------------
//   ------ para ------------------------------------------------------------------- p as h5
//   ------ para ------------------------------------------------------------------- p as h5
//   ------ para ------------------------------------------------------------------- p as h5
//   ------ para ------------------------------------------------------------------- p as h5
//
//   
//   -------- section image  --------+     +----------- section question ----------+ h4 & gray color
//   +                               +     +            section title              + h2 as h3 
//   --------------------------------+     +            section sub title          + h4
//                                         +            section para               + body1
//                                         +            section para               + body1
//                                         +---------------------------------------+
//   
//   --- area image  ------------     --- area image  -----------      --- area image  ---------
//   +                          +     +                         +      +                       +
//   ----------------------------     ------------------------------   -------------------------
//   --- area question ----------     --- area question ------------   --- area question ------- h6 & gray color
//   --- area    title ----------     --- area    title -------        --- area    title ------- h3 as h5      
//   --- area    sub title ------     --- area    sub title ---        --- area    sub title --- h4 as h6        
//   --- para ----             --     --- para ----                    --- para ----          -- body1
//   --- para ----             --     --- para ----                    --- para ----          -- body1 
//   --- para ----             --     --- para ----                    --- para ----          -- body1
//   --- para ----             --     --- para ----                    --- para ----          -- body1
//   --- area    footer ---------     --- area    footer -------       --- area    footer ------ h3 as h5      
//   --- area    sub title ------     --- area    sub title ------     --- area    sub title --- h4 as h6     
//
//   -----section footer -------- h3 as h4
//   -----section sub footer ---- h4 as h5
//
//   ---page footer --------------------------------------------------------------------------- h4
//   ---page sub footer ----------------------------------------------------------------------- h5
//
//
//
// If only one section than don't set section title & subtitle. Rely on the page title/subtitle
//
//
// svg: 'type1animate',  // value with 'xxxanimate' will move the blob and without it will be static
// style: { border: true },  adds a border around the section
//   

/*
   A single page content is supplied for formatting.

   page content has:
   page|svg
   links|image|icon|style|question|title|subtitle|para|footer|subfooter + sections (content)

   section content has:
   section|cols
   draft|links|image|icon|style|question|title|subtitle|para|footer|subfooter + areas (content)
   
   area content has:
   draft|image|icon|style|question|title|subtitle|para|footer|subfooter

   svg - 'type+animate' ; type determines which svg blob and animate determines if it needs to be static or animated
   links - '' | 'yes'  - determines if the section links or area links is to be displayed.  
   Links allow user to go directly to appropriate section or area
   style - Used in each area to define area specific style - style it as different types of cards
   question - is optional and it is listed before the title. Where the title text is an answer to question
   draft - set it to 'yes' if the content is  only for development and pending completion.

   NOTE: A '$' in a para indicates it's a copied text and requires rewording.

 */

