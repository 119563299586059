import * as React from 'react';

function SvgKripaRedBallIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 596 451"
      {...props}
    >
      <defs>
        <linearGradient id="KripaRedBallIcon_svg__c">
          <stop offset={0} stopColor="#999" />
          <stop offset={1} stopColor="#999" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="KripaRedBallIcon_svg__b">
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="KripaRedBallIcon_svg__a">
          <stop offset={0} stopColor="#770900" />
          <stop offset={0.5} stopColor="#da101b" />
          <stop offset={0.75} stopColor="#d2400f" />
          <stop offset={0.938} stopColor="#d44316" stopOpacity={0.615} />
          <stop offset={1} stopColor="#c30000" stopOpacity={0.219} />
        </linearGradient>
        <linearGradient
          xlinkHref="#KripaRedBallIcon_svg__a"
          id="KripaRedBallIcon_svg__e"
          x1={154.286}
          y1={535.219}
          x2={605.714}
          y2={535.219}
          gradientUnits="userSpaceOnUse"
        />
        <radialGradient
          xlinkHref="#KripaRedBallIcon_svg__c"
          id="KripaRedBallIcon_svg__d"
          cx={102.143}
          cy={545.934}
          fx={102.143}
          fy={545.934}
          r={135}
          gradientUnits="userSpaceOnUse"
        />
        <radialGradient
          xlinkHref="#KripaRedBallIcon_svg__b"
          id="KripaRedBallIcon_svg__f"
          gradientUnits="userSpaceOnUse"
          cx={102.143}
          cy={545.934}
          fx={102.143}
          fy={545.934}
          r={135}
        />
      </defs>
      <path
        transform="matrix(1.45573 -.47633 .6458 1.07372 -270.65 -99.441)"
        d="M237.143 545.934a135 135 0 11-270 0 135 135 0 11270 0z"
        fill="url(#KripaRedBallIcon_svg__d)"
      />
      <path
        d="M605.714 535.22a225.714 225.714 0 11-451.428 0 225.714 225.714 0 11451.428 0z"
        transform="rotate(-132.295 234.41 414.566)"
        fill="url(#KripaRedBallIcon_svg__e)"
      />
      <path
        d="M237.143 545.934a135 135 0 11-270 0 135 135 0 11270 0z"
        transform="translate(68.54 -383.823)"
        opacity={0.876}
        fill="url(#KripaRedBallIcon_svg__f)"
      />
    </svg>
  );
}

export default SvgKripaRedBallIcon;
