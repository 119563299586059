import React from 'react'; // , { useState }

// react theme/style
import { makeStyles } from '@material-ui/core/styles'; // useTheme,

// MUI core
import { Typography } from '@material-ui/core';
//

// MUI icons
//

// Gatsby
//

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//

// Local : see src/index.js for available components
//

const useStyles = makeStyles(theme => {
  return {
    header: {
      padding: theme.spacing(0, 0, 0),
    },
  };
});

const SectionHeader = props => {
  //const theme = useTheme();
  const classes = useStyles();

  const { title, subtitle } = props;

  const titleHtml = title ? (
    <Typography component="h2" variant="h4" align="center" color="textPrimary" gutterBottom>
      {title}
    </Typography>
  ) : null;

  const subtitleHtml = subtitle ? (
    <Typography component="h3" variant="h5" align="center" color="textSecondary">
      {subtitle}
    </Typography>
  ) : null;

  return (
    <div className={classes.header}>
      {titleHtml}
      {subtitleHtml}
    </div>
  );
};

export default SectionHeader;
