import React from 'react';

// react theme/style
import { makeStyles, Box } from '@material-ui/core/styles'; // useTheme,

// MUI icons
//

// Gatsby
//

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//

// Local : see src/index.js for available components
//

const useStyles = makeStyles({
  root: {
    overflowY: 'scroll',
  }, // a style rule
});

const SampleMdxComponent = props => {
  //const theme = useTheme();
  const { description } = props;
  const classes = useStyles();

  return (
    <div>hello from MdxSampleComponent and the passed description parameter is : {description}</div>
  );
};

export default SampleMdxComponent;
