import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  //TableFooter,
  //TablePagination,
  TableRow,
  Paper,
  IconButton,
  TableHead,
} from '@material-ui/core';

/*
 Users:
 - DataPage.jsx : Display submissions data as a table

 INPUT: V1

 const tableOptions =  { format: 'form',
                       items: tabListItem,
                       bucket: { p:p,
		                 title: "Basic Sorting Preview",
		       	         columns: [
			                 { title: 'Name', field: 'name', minWidth: 270 },
					 { title: 'Surname', field: 'surname', minWidth: 270 },
					 { title: 'Birth Year', field: 'birthYear', type: 'numeric', minWidth: 270 },
          				 { title: 'Birth Place',
					   field: 'birthCity', , minWidth: 270, 
					   lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
					 },
    					],
			         cells:  [{ name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
			                { name: 'Zerya Betül', surname: 'Baran', birthYear: 2017, birthCity: 34 },
				       ],
			         options: { sorting: true }
                               } 
                    };

 <TableComp {...tableOptions}  />

 */

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 460,
  },
});

/*
export function TableComp({ format, items, bucket }) {
  const classes = useStyles();

  return (
    <MaterialTable
      title={bucket.title}
      columns={bucket.columns}
      data={bucket.cells}
      options={bucket.options}
    />
  );
}
*/

const MuiTableComp = props => {
  const { bucket } = props; // items, format,
  const rows = bucket.cells;
  const columns = bucket.columns;
  const rowsPerPageOptions = bucket.options.rowsPerPageOptions;
  //const handlePagination = bucket.options.handlePagination;
  //const total = Number(bucket.options.total);

  const classes = useStyles2();
  const [page] = React.useState(0); // , setPage
  const [rowsPerPage] = React.useState(rowsPerPageOptions[0]); // , setRowsPerPage

  const emptyRows = 0; //rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  /*
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handlePagination(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    handlePagination(parseInt(event.target.value, 10), 0);
  };
  */

  const dumpColVal = (row, field, j) => {
    return (
      <TableCell component="th" scope="row" key={j}>
        {bucket.options.cellDataFormatter(row, field, row[field])}
      </TableCell>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                title={column.tip}
                key={column.field}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0 && rows.length > rowsPerPage
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, i) => (
            <TableRow key={i}>
              {columns.map((colObj, j) => dumpColVal(row, colObj.field, j))}
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        {/*}
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              colSpan={6}
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
        */}
      </Table>
    </TableContainer>
  );
};

export default MuiTableComp;
