import React from 'react';
import Helmet from 'react-helmet';
import seodata from './seodata.json';

const _SEOJsonLd = ({
  type, // 'inline' | 'build'
  isBlogPost,
  orgSeoCode,
  personSeoCode,
  seoInfo,
  children, // object
}) => {
  if (type === 'inline') {
    return (
      <Helmet>
        <script type="application/ld+json" data-jsonld-type="inline">
          {JSON.stringify(children)}
        </script>
      </Helmet>
    );
  } else {
    // type === 'build'

    // Extract data for org
    const org =
      orgSeoCode && seodata.org[orgSeoCode] ? seodata.org[orgSeoCode] : seodata.org['default'];
    // Extract data for person
    const person =
      personSeoCode && seodata.person[personSeoCode]
        ? seodata.person[personSeoCode]
        : seodata.person['default'];

    const BLOG_IMG_WIDTH = 600; // Matches graphql query in blogs/index.js
    const BLOG_IMG_HEIGHT = 400;
    const ORGANIZATION_LOGO_WIDTH = 48;
    const ORGANIZATION_LOGO_HEIGHT = 48;
    const fullPath = `${org.siteUrl}${seoInfo.path}`; // path to BlogPage or WebPage

    let graph = [];

    //
    // Common   (WebPage & BlogPage)
    //

    //
    // 1) Breadcrumb   (Used for blog and web page)
    //
    const path = seoInfo.path;
    if (path && path.length > 1) {
      // path: /blogs/blog1/
      let pathClean = path[0] === '/' ? path.substr(1) : path;
      pathClean =
        pathClean[pathClean.length - 1] === '/'
          ? pathClean.substr(0, pathClean.length - 1)
          : pathClean;

      const pathParts = pathClean.split('/');
      const pathList = pathParts.map((part, i) => {
        const partialPath = i ? pathParts.slice(0, i + 1).join('/') : part;
        return {
          '@type': 'ListItem',
          position: i + 1,
          item: {
            '@type': 'WebPage',
            '@id': `${org.siteUrl}/${partialPath}/`,
            url: `${org.siteUrl}/${partialPath}/`,
            name: part,
          },
        };
      });

      graph.push({
        '@type': 'BreadcrumbList',
        '@id': `${org.siteUrl}/#breadcrumb`,
        itemListElement: pathList,
      });
    }

    if (isBlogPost) {
      //
      // 2A) BlogPage Specific
      //

      // 2A-1) Image
      graph.push({
        '@type': 'ImageObject',
        '@id': `${org.siteUrl}${seoInfo.path}/#primaryimage`,
        inLanguage: 'en-US',
        url: `${org.siteUrl}/${seoInfo.pic}`,
        width: BLOG_IMG_WIDTH,
        height: BLOG_IMG_HEIGHT,
      });

      // 2A-2) Person
      graph.push({
        '@type': 'Person',
        '@id': `${org.siteUrl}/${person.code}/#person`,
        name: person.name,
        image: {
          '@type': 'ImageObject',
          '@id': `${org.siteUrl}/${person.code}/#personlogo`,
          inLanguage: 'en-US',
          url: person.avatarUrl,
          caption: person.caption,
        },
      });

      // 2A-3) Posting
      graph.push({
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: `${org.siteUrl}${seoInfo.path}`,
        name: seoInfo.title || org.title,
        alternateName: seoInfo.slogan,
        headline: seoInfo.title || org.headline,
        image: {
          '@type': 'ImageObject',
          url: `${org.siteUrl}/${seoInfo.pic}`,
        },
        description: seoInfo.description,
        author: {
          '@type': 'Person',
          name: person.name,
          email: person.email,
        },
        publisher: {
          '@type': 'Organization',
          url: org.siteUrl,
          logo: {
            '@type': 'ImageObject',
            //url: org.logo,
            url: `${org.siteUrl}/${org.logoUrl}`,
            width: ORGANIZATION_LOGO_WIDTH,
            height: ORGANIZATION_LOGO_HEIGHT,
          },
          name: org.name,
        },
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': `${org.siteUrl}/blogs/`,
        },
        datePublished: seoInfo.datePublished,
        dateModified: seoInfo.dateModified,
      });
    } // BlogPage Specific
    else {
      //
      // 2B) WebPage Specific
      //
      /*
      "primaryImageOfPage":{
        "@id": `${org.siteUrl}/xxx`
      },
      */
      const webpage = {
        '@type': 'WebPage',
        '@id': `${org.siteUrl}/#webpage`,
        url: `${org.siteUrl}${seoInfo.path}`,
        name: seoInfo.title || org.title,
        isPartOf: {
          '@id': `${org.siteUrl}/#website`,
          url: `${org.siteUrl}${seoInfo.path}`,
        },
        about: {
          '@id': `${org.siteUrl}/#organization`,
        },
        //"datePublished": datePublished,
        //"dateModified": dateModified,   // "2020-12-16T10:07:24+00:00",
        description: seoInfo.description || org.description,
        inLanguage: 'en-US',
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: [`${org.siteUrl}${seoInfo.path}`],
          },
        ],
      };

      if (seoInfo.path.length > 1) {
        // Home page does not have any breadcrumbs
        webpage['breadcrumb'] = {
          '@id': `${org.siteUrl}/#breadcrumb`,
        };
      }

      graph.push(webpage);
    } // WebPage specific

    let pageSD = {
      '@context': 'https://schema.org',
      '@graph': graph,
    };

    return (
      <Helmet>
        <script type="application/ld+json" data-jsonld-type="build">
          {JSON.stringify(pageSD)}
        </script>
      </Helmet>
    );
  } // 'build' option
};

const SEOJsonLd = React.memo(_SEOJsonLd);
export default SEOJsonLd;

/*
   // else Article ???
   graph.push({
   "@type":"Article",
   "@id":"https://wordlift.io/blog/en/optimize-for-google-discover/#article",
   "isPartOf":{
   "@id":"https://wordlift.io/blog/en/optimize-for-google-discover/#webpage"
   },
   "author":{
   "@id":"https://wordlift.io/blog/en/#/schema/person/574352082cc71dab8d164410f1cabe0a"
   },
   "headline":"The Ultimate Checklist to Optimize Content for Google Discover",
   "datePublished":"2020-12-07T08:35:55+00:00",
   "dateModified":"2020-12-16T10:07:24+00:00",
   "mainEntityOfPage":{
   "@id":"https://wordlift.io/blog/en/optimize-for-google-discover/#webpage"
   },
   "commentCount":0,
   "publisher":{
   "@id":"https://wordlift.io/blog/en/#organization"
   },
   "image":{
   "@id":"https://wordlift.io/blog/en/optimize-for-google-discover/#primaryimage"
   },
   "articleSection":"seo,seo &amp; content marketing news",
   "inLanguage":"en-US",
   "potentialAction":[
   {
   "@type":"CommentAction",
   "name":"Comment",
   "target":[
   "https://wordlift.io/blog/en/optimize-for-google-discover/#respond"
   ]
   }
   ]
   });
 */
