import React from 'react'; // , { useState }

// react theme/style
import { makeStyles } from '@material-ui/core'; // useTheme,

// MUI core
//

// MUI icons
//

// Gatsby
//import { StaticQuery, graphql } from 'gatsby';

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//

// Local : see src/index.js for available components
//import { GlobalContextProvider } from '../../../src';

//import css from '../../css/site.css';

const useStyles = makeStyles(theme => {
  return {
    backpanel: {
      background: theme.custom.backpanelBG,
    },
  };
});

const ContextLayout = props => {
  //const theme = useTheme(); // use the initTheme from imported file
  //console.log('************** ContextLayout theme : ', theme);

  const classes = useStyles();

  const { children, contextprovider } = props;

  //
  //
  //      <ContextProvider>{children}</ContextProvider>
  const ContextProvider = contextprovider;
  return (
    <div className={classes.backpanel}>
      <ContextProvider>{children}</ContextProvider>
    </div>
  );
};

export default ContextLayout;
