import React from 'react'; // , { useState }

// react theme/style
import { makeStyles } from '@material-ui/core/styles'; // useTheme,

// MUI core
import { Typography } from '@material-ui/core'; // Link, Container, Button, Grid, Paper, Avatar,

// MUI icons
//

// Gatsby
//import { Link, graphql } from 'gatsby';

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//

// Local : see src/index.js for available components
//

const useStyles = makeStyles(theme => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',

      padding: theme.spacing(theme.rspv.sectionGapSmall),
      marginBottom: theme.spacing(theme.rspv.sectionGapSmall),

      '@media (min-width: 600px)': {
        padding: theme.spacing(theme.rspv.btnPaddingMedium),
        marginBottom: theme.spacing(theme.rspv.sectionGapMedium),
      },
      '@media (min-width: 900px)': {
        padding: theme.spacing(theme.rspv.btnPaddingLarge),
        marginBottom: theme.spacing(theme.rspv.sectionGapLarge),
      },
    },
    title: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(theme.rspv.sectionGapSmall),

      '@media (min-width: 600px)': {
        marginBottom: theme.spacing(theme.rspv.sectionGapMedium),
      },
      '@media (min-width: 900px)': {
        marginBottom: theme.spacing(theme.rspv.sectionGapLarge),
      },
    },
    subtitle: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(theme.rspv.sectionGapSmall),

      '@media (min-width: 600px)': {
        marginBottom: theme.spacing(theme.rspv.sectionGapMedium),
      },
      '@media (min-width: 900px)': {
        marginBottom: theme.spacing(theme.rspv.sectionGapLarge),
      },
    },
    body: {
      marginBottom: theme.spacing(theme.rspv.sectionGapSmall),

      '@media (min-width: 600px)': {
        marginBottom: theme.spacing(theme.rspv.sectionGapMedium),
      },
      '@media (min-width: 900px)': {
        marginBottom: theme.spacing(theme.rspv.sectionGapLarge),
      },
    },
    tail: {
      marginBottom: theme.spacing(theme.rspv.sectionGapSmall),

      '@media (min-width: 600px)': {
        marginBottom: theme.spacing(theme.rspv.sectionGapMedium),
      },
      '@media (min-width: 900px)': {
        marginBottom: theme.spacing(theme.rspv.sectionGapLarge),
      },

      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  };
});

const SectionWrapper = props => {
  //const theme = useTheme();
  const classes = useStyles();

  const { title, subtitle, body, tail } = props;

  return (
    <div className={classes.wrapper}>
      <Typography variant="h1" component="h1" className={classes.title}>
        {title}
      </Typography>

      <Typography variant="h3" component="h3" className={classes.subtitle}>
        {subtitle}
      </Typography>

      <Typography variant="body1" className={classes.body}>
        {body}
      </Typography>

      <Typography component="div" className={classes.tail}>
        {tail}
      </Typography>
    </div>
  );
};

export default SectionWrapper;
