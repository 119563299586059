// This is a newer Tab component then TabCtnr

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';

/*
 parameters:
   tabOptions: {
       format: 'emert',
       items: tabList,
       bucket: {
           orientation: "vertical"      // 'vertical' | 
           variant: "scrollable"        // 'scrollable' |   
           value: 0                     // tab index as current selection
           onChange: {onChange}     // callback to detect tab change
           ariaLabel: "Vertical tabs"   // ARIA label
      }
   }

   tabList: [
   ];     

   
*/

function TabPanel(props) {
  const { children, value, index, unqKey, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${unqKey}-${index}`}
      aria-labelledby={`vertical-tab-${unqKey}-${index}`}
      {...other}
    >
      {value === index && <Box className={other.classeName}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index, unqKey) {
  return {
    id: `vertical-tab-${unqKey}-${index}`,
    'aria-controls': `vertical-tabpanel-${unqKey}-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  tabs: {
    backgroundColor: theme.palette.grey['100'],
    marginRight: '0.4rem',
    minWidth: '10rem', // Required when tabs are verticle - for width of the gray tab area
  },
  panel: {
    width: '98%',
  },
  headers: {
    minWidth: '40px',
    fontSize: '0.47rem',
    '@media (min-width: 400px)': {
      fontSize: '0.68rem',
    },
    '@media (min-width: 600px)': {
      fontSize: '1.0rem',
    },
    '@media (min-width: 900px)': {
      fontSize: '1.2rem',
    },
  },
}));

/*
.PrivateTabIndicator-vertical-361 {
  width: 6px;  // Adjust the thickness of tab marker
}
.PrivateTabIndicator-root-358 {
 // transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;  // Remove transition to prevent flash when moving to next slide
*/

export default function TabComp({ items, bucket }) {
  // format,
  const classes = useStyles();
  const [value, setValue] = React.useState(bucket.value);
  const unqKey = bucket.unqKey || 'unqkey';

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const item = items[newValue];
    bucket.onChange(event, bucket.p, item, newValue);
  };

  const tabLabels = items.map((item, i) => (
    <Tab
      label={item.display}
      key={item.code}
      className={classes.headers}
      {...a11yProps(i, unqKey)}
    />
  ));

  const tabPanels = items.map((item, i) => (
    <TabPanel value={value} index={i} unqKey={unqKey} key={'panel-' + i} className={classes.panel}>
      {item.bucket.children}
    </TabPanel>
  ));

  // indicatorColor="secondary"
  // selected={classes.selected}
  return (
    <div className={classes.root}>
      <Tabs
        orientation={bucket.orientation}
        variant={bucket.variant}
        textColor="secondary"
        className={classes.tabs}
        value={value}
        onChange={handleChange}
        aria-label={bucket.ariaLabel || 'Vertical tabs'}
      >
        {tabLabels}
      </Tabs>

      {tabPanels}
    </div>
  );
}
