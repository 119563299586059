import React, { useState, createContext, useEffect } from 'react';
import { myFetchAPI } from '../../../src';
//import { navigate } from 'gatsby';

function getServerUrl() {
  if (typeof window !== 'undefined') return window.location.origin;
  else return null;
}

async function isReachable(url) {
  /**
   * Note: fetch() still "succeeds" for 404s on subdirectories,
   * which is ok when only testing for domain reachability.
   *
   * Example:
   *   https://google.com/noexist does not throw
   *   https://noexist.com/noexist does throw
   */
  try {
    const resp = await fetch(url, { method: 'HEAD', mode: 'cors' });
    if (resp && (resp.ok || resp.type === 'opaque')) {
      return true;
    } else {
      console.warn('PWA:fetch() - [conn test failure]:', resp);
      return false;
    }
  } catch (e) {
    return false;
  }
}

function getUrlParm(name) {
  let value = null;
  if (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.pathname &&
    (window.location.pathname.includes('/app/myemerts') ||
      window.location.pathname.includes('/getstarted'))
  ) {
    // extract query parameters
    // http://localhost:8000/app/myemerts?display=list&type=search&term=sugar
    const searchStr = document.location.search.substring(1);
    if (searchStr) {
      value = new URLSearchParams(searchStr).get(name);
      value = (value && value.substr(0, 25)) || ''; // For safety reqson we restrict parameter values to 25 characters
    }
  }
  return value;
}

export const GlobalContext = createContext();
export const GlobalContextProvider = props => {
  const [token, setToken] = useState(null);
  const isBrowser = typeof window !== 'undefined';
  const themeMode = isBrowser && localStorage.getItem(process.env.GATSBY_THEME_MODE_KEY);
  const initGlobal = {
    isDev: process.env.GATSBY_ACTIVE_ENV !== 'production',
    isOnline: true, // This set/rset within PWA.jsx
    consumer: null, // Refers to an instance of Consumer class
    consumerStatus: 'init',
    token: null,
    user: null, // upap's user
    preference: null,
    permissions: null,
    plan: getUrlParm('plan'),
    searchTerm: getUrlParm('term') || '',
    emertType: getUrlParm('type') || '', // options: 'recent' | 'search' | 'alerts'
    displayMode: getUrlParm('display') || '', // options: 'table' | 'list' | 'slides'
    monthYear: null, // { month: M, year: NNNN } note: M starts at 0
    slideNdx: 0,
    account: null,
    themeMode: themeMode || 'light',
    persistThemeMode: function (mode) {
      isBrowser && localStorage.setItem(process.env.GATSBY_THEME_MODE_KEY, mode);
    },
    slider: null,
    status: 'init', // 'init', 'authdone', 'authfail', 'initdone'
    stats: {},
    isLoggedIn: function () {
      return !!this.token;
    },
    getExternalUrl: function (nav, plan, text) {
      let url = '#';
      if (nav === 'waiver') {
        // waver
        // http://localhost:8080/index_lunch.html?account=7&form=2&plan=xxxx
        url = process.env.GATSBY_LAUNCH_APP_URL + '?account=7&form=2&signin=yes&apptgt=emert';
      } else if (nav === 'cancel') {
        // Cancel Subscription
        // http://localhost:8080/index_lunch.html?account=7&form=3
        url = process.env.GATSBY_LAUNCH_APP_URL + '?account=7&form=3&signin=yes&apptgt=emert';
      } else if (nav === 'payments') {
        // payments
        // http://localhost:8080/index_lunch.html?account=7&form=5
        url = process.env.GATSBY_LAUNCH_APP_URL + '?account=7&form=5&signin=yes&apptgt=emert';
      } else if (nav === 'getnotified') {
        // GetNotified
        // http://localhost:8080/index_lunch.html?account=7&form=4
        url = process.env.GATSBY_LAUNCH_APP_URL + '?account=7&form=4&signin=no&apptgt=emert';
      }
      return url;
    },
    redirectUrl: function (nav, plan) {
      let win;
      if (nav === 'signin') {
        const url =
          process.env.GATSBY_AUTH_APP_URL +
          '?apptgt=emert&nav=signin&target=' +
          process.env.GATSBY_EMERT_SITE_URL +
          'app/myemerts';
        window.location = url;
        //navigate(url);
      } else if (nav === 'signout') {
        const url =
          process.env.GATSBY_AUTH_APP_URL +
          '?apptgt=emert&nav=signout&target=' +
          process.env.GATSBY_EMERT_SITE_URL;
        window.location = url;
        //navigate(url);
      } else if (nav === 'signup') {
        // loc === signup
        const url =
          process.env.GATSBY_AUTH_APP_URL +
          '?nav=signup&apptgt=emert&plan=' +
          plan +
          '&target=' +
          process.env.GATSBY_EMERT_SITE_URL;
        win = window.open(url, '_blank');
        win.focus();
      } else if (nav === 'forgotpwd') {
        // loc === signup
        const url = process.env.GATSBY_AUTH_APP_URL + '?nav=forgot&apptgt=emert';
        win = window.open(url, '_blank');
        win.focus();
      } else if (nav === 'changeid') {
        // loc === signup
        const url = process.env.GATSBY_AUTH_APP_URL + '?nav=changeid&apptgt=emert';
        win = window.open(url, '_blank');
        win.focus();
      } else if (nav === 'changepwd') {
        // loc === signup
        const url = process.env.GATSBY_AUTH_APP_URL + '?nav=changepwd&apptgt=emert';
        win = window.open(url, '_blank');
        win.focus();
      }
    },
    logout: function () {
      this.token = null;
      isBrowser && localStorage.setItem(process.env.GATSBY_JWT_KEY, '');
      setToken(null);
      this.token = null;
      this.user = null;
      // Need to clear token from global
      // and a re-render so nav bar does not display LOGOUT but displays LOGIN
    },
  };

  const [global, setGlobal] = useState(initGlobal);

  useEffect(async () => {
    // Upon page reload, set the onine/offline indicator
    const myUrl = getServerUrl();
    let isOnlineStatus = true;
    if (!myUrl || typeof window !== 'undefined') {
      try {
        isOnlineStatus = await isReachable(myUrl);
      } catch (e) {
        isOnlineStatus = false;
      }
    }

    // 1. Grab token from local store
    const token = isBrowser && localStorage.getItem(process.env.GATSBY_JWT_KEY);

    // 2. Validate token ...
    //    Discard token if it's not valid
    //    Otherwise save it state and global context
    if (token && isOnlineStatus) {
      //console.log('GlobalContext: authenticate token : ' + token);

      myFetchAPI({ type: 'authenticate', id: null, limit: 1, skip: 0, term: '', token })
        .then(resp => {
          // resp: { code: 401, data: {}, msg: 'Unauthorized' }
          // resp.data: { className: "not-authenticated", code: 401, data: {}, errors: {}, message: "jwt expired", name: "NotAuthenticated" }
          // resp.data.data: {name: "TokenExpiredError", message: "jwt expired", expiredAt: "2020-03-13T19:23:31.000Z"}

          if (resp.code === 201) {
            // resp.data holds: accessToken, authentication.strategy/payload, user

            // 3. get upap
            myFetchAPI({ type: 'upap', id: null, limit: 1, skip: 0, term: '', token })
              .then(buffer => {
                const upap = buffer.data;
                const newGlobal = {
                  isOnline: isOnlineStatus,
                  token: token,
                  status: 'authdone', // 'init', 'authdone', 'authfail', 'initdone'
                  user: (upap && upap.user) || null,
                  preference: (upap && upap.members && upap.members[0].preference) || null,
                  permissions: (upap && upap.members && upap.members[0].permissions) || null,
                  account: upap && upap.members && upap.members[0].account,
                };
                // save the token
                //setToken(token);
                setGlobal({ ...global, ...newGlobal });
                //console.log('GlobalContext: updated ');
              })
              .catch(e => {
                console.error('GlobalContext error', e);
                const newGlobal = {
                  isOnline: isOnlineStatus,
                  token: null,
                  status: 'authfail', // 'init', 'authdone', 'authfail', 'initdone'
                  consumerStatus: 'initfail',
                };
                // save the token
                //setToken(token);
                setGlobal({ ...global, ...newGlobal });
              });
          } else {
            // Unable to validate token
            // Discard the token
            localStorage.setItem(process.env.GATSBY_JWT_KEY, '');
            const newGlobal = {
              isOnline: isOnlineStatus,
              token: null,
              status: 'authfail', // 'init', 'authdone', 'authfail', 'initdone'
              consumerStatus: 'initfail',
            };
            // save the token
            //setToken(token);
            setGlobal({ ...global, ...newGlobal });
          }
        })
        .catch(e => {
          console.error('GlobalContext authenticate token error', e);
          const newGlobal = {
            isOnline: isOnlineStatus,
            token: null,
            status: 'authfail', // 'init', 'authdone', 'authfail', 'initdone'
            consumerStatus: 'initfail',
          };
          // save the token
          //setToken(token);
          setGlobal({ ...global, ...newGlobal });
        });
    } else {
      // If no token, take user to home page
      //console.log('GlobalContext: window.location.pathname: ' + window.location.pathname);

      const newGlobal = {
        isOnline: isOnlineStatus,
        token: null,
        status: 'initdone', // 'init', 'authdone', 'authfail', 'initdone'
        consumerStatus: 'initfail',
      };
      // save the token
      //setToken(token);
      setGlobal({ ...global, ...newGlobal });
    } // if (token) check
  }, []);

  /* useMemo to ensure reference remains the same ... and use the value in provider
  const value = useMemo(() => ({
    isGlobalSpinnerOn,
    setGlobalSpinner
  }), [isGlobalSpinnerOn])
  */

  if (typeof window !== 'undefined') {
    console.log(
      'GlobalContext: Running Mode : *** !!! ' + process.env.GATSBY_ACTIVE_ENV + ' !!! ***',
    );
  }

  async function handleConnection() {
    //console.log('PWA: handleConnection() online : ' + navigator.onLine);
    if (navigator.onLine) {
      try {
        let isOnlineStatus = await isReachable(getServerUrl());
        setGlobal({ ...global, isOnline: isOnlineStatus });
      } catch (e) {
        setGlobal({ ...global, isOnline: false });
      }
    } else {
      // handle offline status
      setGlobal({ ...global, isOnline: false });
    }
  }

  if (isBrowser) window.addEventListener('online', handleConnection);
  if (isBrowser) window.addEventListener('offline', handleConnection);

  //
  // NOTE: The value is passed by reference.
  //       Anytime the reference changes, all components using this context are also re-rendered.
  //
  return (
    <GlobalContext.Provider value={{ global, setGlobal }}>{props.children}</GlobalContext.Provider>
  );
};
