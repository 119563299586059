import React from 'react'; // , { useState, useEffect, useContext }

// react theme/style
import { makeStyles } from '@material-ui/core/styles'; // useTheme,

// MUI core
import { Button, Grid } from '@material-ui/core'; // Link, Avatar, Typography
//import { FormLabel, FormControl, FormControlLabel, Divider, Radio, RadioGroup } from '@material-ui/core';
//import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';

// MUI icons
//import { AccountCircle as AccountCircleIcon } from '@material-ui/icons';
//import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

// Gatsby
//import { Link, graphql } from 'gatsby';

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel';
//import nprogress from 'nprogress';
//import { useDebouncedCallback } from 'use-debounce';

// Local : see src/index.js for available components
import { SectionWrapper, SectionLayout } from '../../../src';

const useStyles = makeStyles(theme => {
  return {
    section: {
      marginBottom: theme.spacing(theme.custom.sectionGap),
    },
    textHilite: {
      color: theme.palette.secondary.main,
    },
    ctabtn: {
      margin: theme.spacing(1, 10),
      padding: theme.spacing(2),
      width: '300px',
      letterSpacing: '0.2em',
    },
  };
});

//
// Compose Section Areas
//
const SampleSectionTwoCol = props => {
  //const theme = useTheme();
  const classes = useStyles();

  //
  // Title Area
  //
  const titleLeft = (
    <>
      <span>Left Col Title </span>
    </>
  );
  const subtitleLeft = 'Left Col Sub Title';

  //
  // Body Area
  //
  const bodyLeft = (
    <>
      <span>Left Col Body Text</span>
    </>
  );

  //
  // Tail Area
  //
  const tailLeft = (
    <Button href="/pricing" variant="contained" color="secondary" className={classes.ctabtn}>
      CTA Left
    </Button>
  );

  //
  // Title Area
  //
  const titleRight = (
    <>
      <span>Right Col Title </span>
    </>
  );
  const subtitleRight = 'Right Col Sub Title';

  //
  // Body Area
  //
  const bodyRight = (
    <>
      <span>Right Col Body Text</span>
    </>
  );

  //
  // Tail Area
  //
  const tailRight = (
    <Button href="/pricing" variant="contained" color="secondary" className={classes.ctabtn}>
      CTA Right
    </Button>
  );

  return (
    <SectionLayout>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <SectionWrapper
            title={titleLeft}
            subtitle={subtitleLeft}
            body={bodyLeft}
            tail={tailLeft}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SectionWrapper
            title={titleRight}
            subtitle={subtitleRight}
            body={bodyRight}
            tail={tailRight}
          />
        </Grid>
      </Grid>
    </SectionLayout>
  );
};

export default SampleSectionTwoCol;
