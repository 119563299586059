import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Typography } from '@material-ui/core';

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 1 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    //width: 500,
  },
}));

/*
 Users:
 - ConfigPage.jsx : Display component categories and individual component properties in two separate tabs

 INPUT: V1

 const tabListItem = { code: 'Components',
                       display: 'Components',
                       bucket: { children: 
                               }
                     };      

 const tabOptions =  { format: 'form',
                       items: tabListItem,
                       bucket: { p:p,
		       	       handleChange: 
                               } 
                     };

 <TabCtnr {...tabOptions}  />

 */

const TabCtnr = ({ items, bucket }) => {
  // format,
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
    const item = items[newValue];
    bucket.handleChange(event, bucket.p, item, newValue);
    //    onChange(event, newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
    //  onChangeIndex(index);
  }

  const tabLabels = items.map(item => <Tab label={item.display} key={item.code} />);
  const tabPanels = items.map((item, i) => (
    <TabContainer dir={theme.direction} key={item.code}>
      {' '}
      {item.bucket.children}{' '}
    </TabContainer>
  ));

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {tabLabels}
        </Tabs>
      </AppBar>

      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tabPanels}
      </SwipeableViews>
    </div>
  );
};

export default TabCtnr;
