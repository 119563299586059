import React from 'react'; // , { useState, useEffect, useContext }

// react theme/style
import { makeStyles } from '@material-ui/core/styles'; // useTheme,

// MUI core
import { Button, Grid } from '@material-ui/core'; // Link, Avatar, Typography
//import { FormLabel, FormControl, FormControlLabel, Divider, Radio, RadioGroup } from '@material-ui/core';
//import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';

// MUI icons
//import { AccountCircle as AccountCircleIcon } from '@material-ui/icons';
//import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

// Gatsby
//import { Link, graphql } from 'gatsby';

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel';
//import nprogress from 'nprogress';
//import { useDebouncedCallback } from 'use-debounce';

// Local : see src/index.js for available components
import { SectionWrapper, SectionLayout } from '../../../src';

const useStyles = makeStyles(theme => {
  const rand = Math.floor(Math.random() * 10);
  const heroImage = theme.heroImages[rand];

  return {
    sectionbg: {
      position: 'relative',

      backgroundColor: theme.palette.grey[800],
      backgroundImage: `linear-gradient(to right, #ffffff, transparent 20%),  url(${heroImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',

      marginBottom: theme.spacing(theme.custom.sectionGap),
      color: theme.palette.common.white,
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'rgba(f,f,f,.1)',
    },
    textHilite: {
      color: theme.palette.secondary.main,
    },
    ctabtn: {
      margin: theme.spacing(1, 10),
      padding: theme.spacing(2),
      width: '300px',
      letterSpacing: '0.2em',
    },
  };
});

//
// Compose Section Areas
//
const SampleSectionBG = props => {
  //const theme = useTheme();
  const classes = useStyles();

  //
  // Title Area
  //
  const title = (
    <>
      <span className={classes.textHilite}>EMERGENCY</span>&nbsp;
      <span>Recall </span>
      <span className={classes.textHilite}>ALERT</span>
    </>
  );
  const subtitle = 'Be Informed, Aware and Safe. ';

  //
  // Body Area
  //
  const body = (
    <>
      <span>
        Let our timely alerts keep you informed and aware of all voluntary and regulatory recalls.
        You can choose and filter notifications that are relevant and suppress others. Be Vigilant
        of goods and products you consume to keep your self and your loved ones safe.
      </span>
    </>
  );

  //
  // Tail Area
  //
  const tail = (
    <Button href="/pricing" variant="contained" color="secondary" className={classes.ctabtn}>
      Subscribe
    </Button>
  );

  return (
    <SectionLayout>
      <div className={classes.sectionbg}>
        <div className={classes.overlay} />
        <Grid container>
          <Grid item xs={9} sm={5}>
            <SectionWrapper title={title} subtitle={subtitle} body={body} tail={tail} />
          </Grid>
        </Grid>
      </div>
    </SectionLayout>
  );
};

export default SampleSectionBG;
