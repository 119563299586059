var dayjs = require('dayjs');

var utc = require('dayjs/plugin/utc');

var relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(utc);
dayjs.extend(relativeTime);
module.exports = dayjs; //import dayjs from 'dayjs';
//import 'dayjs/locale/de';
//import AdvancedFormat from ‘dayjs/plugin/advancedFormat’;
//import relativeTime from 'dayjs/esm/plugin/relativeTime';
//dayjs.locale('de');
//dayjs.extend(AdvancedFormat);