/*
 Used for rendering dynamic (fetches from server) emert details as static page or in a modal
 It is linked from emerts listing(Notifications/Search/Recent Emerts).
 Upon clicking, it fetches emert details from server and displays the detail.
 There is another one (staticEmertItemTemplate.js) which displays for static data
*/

import React from 'react'; // , { useState } //

// react theme/style
import { makeStyles } from '@material-ui/core/styles'; // useTheme,

// MUI core
//import { Grid, Paper } from '@material-ui/core'; // Link, Button, Paper, Avatar, Typography

// MUI icons
import CloseIcon from '@material-ui/icons/Close';

// Gatsby
import { Link } from 'gatsby'; // , graphql

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
//import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';

// Local : see src/index.js for available components
// import { RecentEmertItem } from '../../../src'; //

const useStyles = makeStyles(theme => {
  return {
    popup: {
      backgroundColor: 'white',
      overflow: 'hidden',
      padding: '8px',
    },
    header: {},
    left: {
      fontSize: '150%',
      textDecoration: 'none',
    },
    right: {
      float: 'right',
    },
    body: {},
    footer: {
      position: 'absolute',
      left: '8px',
      bottom: '8px',
    },
  };
});

//
// Compose Page Sections
//
const Modal = props => {
  //const theme = useTheme();
  const classes = useStyles();

  const { closeTo } = props;

  return (
    <div className={classes.popup}>
      <div className={classes.header}>
        <Link to={closeTo} className={classes.right}>
          <CloseIcon />
        </Link>
      </div>

      <div className={classes.body}>{props.children}</div>

      <div className={classes.footer}>
        <Link to={closeTo} className={classes.left}>
          Close
        </Link>
      </div>
    </div>
  );
};

export default Modal;
